import conf from '../../public/conf.json';
export const Config = {
  dev: JSON.parse(process.env.VUE_APP_DEV),
  ValidateOrderBacode(barcode = '') {
    let validFormat = /^\d+:/.test(barcode);
    if (!validFormat) validFormat = /^PP\d/.test(barcode);
    return validFormat ? this.dev ? /^1|PP/.test(barcode) : true : false;
  },
  ValidatePublicIpAddress(packingIp = '', publicIp = '') {
    if (this.dev) {
      if (!new RegExp(packingIp).test(publicIp)) {
        return packingIp.split(',').some(ip => ip == publicIp);
      } else return true;
    }
    return new RegExp(packingIp).test(publicIp);
  },
  ForceUpdateToLatestVersion() {
    window.fetch('../conf.json?' + new Date().getTime()).then(response => {
      if (response && response.status == 200) return response.json();
    }).then(json => {
      if (!json) return;
      if (json.version == conf.version) return;
      window.location.replace(window.location.href + '?' + new Date().getTime());
    }).catch(error => error);
  }
}