import { BPA } from '@/helpers/BPA';

export const Permissions = {
  Check(required) {
    const perms = BPA.session.auth().user.permission;
    if (Array.isArray(required)) {
      return !!perms.some(x => required.includes(x));
    } else {
      return false;
    }
  },
  GetFallback(routes) {
    let route = null;
    const perms = BPA.session.auth().user.permission;
    const loop = (views) => {
      for (let view of views) {
        if ('children' in view) {
          for (let child of view.children) {
            if ('meta' in child && 'permission' in child.meta) {
              if (perms.some(x => child.meta.permission.includes(x))) {
                route = 'alias' in child ? child.alias[0] : child.name;
                break;
              }
            } else {
              loop(view.children);
            }
          }
        } else {
          if ('meta' in view && 'permission' in view.meta) {
            if (perms.some(x => view.meta.permission.includes(x))) {
              route = 'alias' in view ? view.alias[0] : view.name;
              break;
            }
          }
        }
      }
    }
    loop(routes);
    return route;
  }
};
