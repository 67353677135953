import Vue                     from 'vue';
import VueI18n                 from 'vue-i18n';
import VueRouter               from 'vue-router';
import Vselect                 from 'vue-select';
import VueCroppie              from 'vue-croppie';
import VueTimepicker           from 'vue-time-picker';
import VueCtkDateTimePicker    from 'vue-ctk-date-time-picker';
import VueFlags                from '@growthbunker/vueflags';
import VueQrcode               from '@chenfengyuan/vue-qrcode';

/*** Direct import of components ***/
/*
import BaseTemplate             from '@/templates/BaseTemplate';
import CleanTemplate            from '@/templates/CleanTemplate';
import LoginTemplate            from '@/templates/LoginTemplate';
import PublicTemplate           from '@/templates/PublicTemplate';

import Login                    from '@/views/Login';
import Punchin                  from '@/views/Punchin';
import Tracking                 from '@/views/Tracking';
import Stats                    from '@/views/Stats';

import Punchclock               from '@/views/Punchclock';
import Scan                     from '@/views/Scan';
import Parcels                  from '@/views/Parcels';
import Products                 from '@/views/Products';
import Dashboard                from '@/views/Dashboard';
import Users                    from '@/views/Users';
import Salary                   from '@/views/Salary';
import Lists                    from '@/views/Lists';
import Statistics               from '@/views/Statistics';
import Schedule                 from '@/views/Schedule';
//import Profile                  from '@/views/Profile';
import Settings                 from '@/views/Settings';
import Trophies                 from '@/views/Trophies';

//import PreviewEmailTemplate     from '@/views/PreviewEmailTemplate';

import UsersList                from '@/components/blocks/Users/UsersList';
import UsersGroups              from '@/components/blocks/Users/UsersGroups';

import ProductsSerials          from '@/components/blocks/Products/ProductsSerials';

import ParcelsOrdersList        from '@/components/blocks/Parcels/ParcelsOrdersList';
import ParcelsShipmenstList     from '@/components/blocks/Parcels/ParcelsShipmenstList';
import ParcelsMessagesList      from '@/components/blocks/Parcels/ParcelsMessagesList';
import ParcelsCustomersList     from '@/components/blocks/Parcels/ParcelsCustomersList';

import SalaryPayList            from '@/components/blocks/Salary/SalaryPayList';

import ListsPickLists           from '@/components/blocks/Lists/ListsPickLists';
import ListsCollectLists        from '@/components/blocks/Lists/ListsCollectLists';
import ListsInvoices            from '@/components/blocks/Lists/ListsInvoices';

import StatisticsPickerStats    from '@/components/blocks/Statistics/StatisticsPickerStats';
import StatisticsPackerStats    from '@/components/blocks/Statistics/StatisticsPackerStats';

import ScheduleHoliday          from '@/components/blocks/Schedule/ScheduleHoliday';
import ScheduleHolidayOverview  from '@/components/blocks/Schedule/ScheduleHolidayOverview';
import ScheduleHolidayApprove   from '@/components/blocks/Schedule/ScheduleHolidayApprove';

//import DashboardLive            from '@/components/blocks/Dashboard/DashboardLive';
//import DashboardPicker          from '@/components/blocks/Dashboard/DashboardPicker';
//import DashboardPacker          from '@/components/blocks/Dashboard/DashboardPacker';
//import DashboardCarrier         from '@/components/blocks/Dashboard/DashboardCarrier';

import NotFound                 from '@/components/404';
*/

/*** Lazy loaded components ***/
const BaseTemplate = () => import('@/templates/BaseTemplate');
const CleanTemplate = () => import('@/templates/CleanTemplate');
const LoginTemplate = () => import('@/templates/LoginTemplate');
const PublicTemplate = () => import('@/templates/PublicTemplate');

const Login = () => import('@/views/Login');
//const Punchin = () => import('@/views/Punchin');
const Tracking = () => import('@/views/Tracking');
const Stats = () => import('@/views/Stats');

const Punchclock = () => import('@/views/Punchclock');
const Scan = () => import('@/views/Scan');
const Parcels = () => import('@/views/Parcels');
const Products = () => import('@/views/Products');
const Dashboard = () => import('@/views/Dashboard');
const Users = () => import('@/views/Users');
const Salary = () => import('@/views/Salary');
const Lists = () => import('@/views/Lists');
const Statistics = () => import('@/views/Statistics');
const Schedule = () => import('@/views/Schedule');
const Settings = () => import('@/views/Settings');

const PreviewEmailTemplate = () => import('@/views/PreviewEmailTemplate');

const UsersList = () => import('@/components/blocks/Users/UsersList');
const UsersGroups = () => import('@/components/blocks/Users/UsersGroups');

const ProductsSerials = () => import('@/components/blocks/Products/ProductsSerials');

const ParcelsOrdersList = () => import('@/components/blocks/Parcels/ParcelsOrdersList');
const ParcelsShipmenstList = () => import('@/components/blocks/Parcels/ParcelsShipmenstList');
const ParcelsMessagesList = () => import('@/components/blocks/Parcels/ParcelsMessagesList');
const ParcelsCustomersList = () => import('@/components/blocks/Parcels/ParcelsCustomersList');
const ParcelsBypassOrder = () => import('@/components/blocks/Parcels/ParcelsBypassOrder');

const SalaryPayList = () => import('@/components/blocks/Salary/SalaryPayList');

const ListsPickLists = () => import('@/components/blocks/Lists/ListsPickLists');
const ListsCollectLists = () => import('@/components/blocks/Lists/ListsCollectLists');
const ListsInvoices = () => import('@/components/blocks/Lists/ListsInvoices');

const StatisticsPickerStats = () => import('@/components/blocks/Statistics/StatisticsPickerStats');
const StatisticsPackerStats = () => import('@/components/blocks/Statistics/StatisticsPackerStats');

const ScheduleHoliday = () => import('@/components/blocks/Schedule/ScheduleHoliday');
const ScheduleHolidayOverview = () => import('@/components/blocks/Schedule/ScheduleHolidayOverview');
const ScheduleHolidayApprove = () => import('@/components/blocks/Schedule/ScheduleHolidayApprove');

/* const DashboardLive = () => import('@/components/blocks/Dashboard/DashboardLive');
const DashboardPicker = () => import('@/components/blocks/Dashboard/DashboardPicker');
const DashboardPacker = () => import('@/components/blocks/Dashboard/DashboardPacker');
const DashboardCarrier = () => import('@/components/blocks/Dashboard/DashboardCarrier'); */
const DashboardDeadline = () => import('@/components/blocks/Dashboard/DashboardDeadline');
/* const DashboardOrders = () => import('@/components/blocks/Dashboard/DashboardOrders'); */
const DashboardPunchClock = () => import('@/components/blocks/Dashboard/DashboardPunchClock')

const NotFound = () => import('@/components/404');

const RMAPreview = () => import('@/components/blocks/Settings/RMASettings/RMALandingpage/RMAPreview');

import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import 'croppie/croppie.css';

Vue.use(VueI18n);
Vue.use(VueRouter);
Vue.use(VueCroppie);
Vue.component('v-select', Vselect);
Vue.component(VueQrcode.name, VueQrcode);
Vue.use(VueFlags, {iconPath: '/img/flags'});
Vue.component(VueTimepicker.name, VueTimepicker);
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);

const routes = [
  {
    path: '',
    component: BaseTemplate,
    children: [
      {
        path: '/punchclock',
        name: 'punchclock',
        component: Punchclock,
        meta: {
          ipLocked: true,
          permission: [/*0, 1, 2*/ 'admin', 'punchclock_admin', 'punchclock']
        }
      },
      {
        path: '/',
        name: 'scan',
        alias: ['scan'],
        component: Scan,
        meta: {
          ipLocked: true,
          permission: [/*0, 3, 4*/ 'admin', 'scan_admin', 'scan']
        }
      },
      /*
      {
        path: '/trophies',
        name: 'trophies',
        component: Trophies,
        meta: {
          permission: [/*0, 5, 6/ 'admin', 'trophies_admin', 'trophies']
        }
      },
      */
      {
        path: '/parcels',
        alias: ['orders'],
        component: Parcels,
        meta: {
          permission: [/*0, 7, 8*/ 'admin', 'parcels_admin', 'parcels']
        },
        children: [
          {
            path: 'orders',
            name: 'orders',
            component: ParcelsOrdersList,
            meta: {
              permission: [/*0, 7, 8*/ 'admin', 'parcels_admin', 'parcels']
            }
          },
          {
            path: 'shipments',
            name: 'shipments',
            component: ParcelsShipmenstList,
            meta: {
              permission: [/*0, 7, 8*/ 'admin', 'parcels_admin', 'parcels']
            }
          },
          {
            path: 'customers',
            name: 'customers',
            component: ParcelsCustomersList,
            meta: {
              permission: [/*0, 7, 8, 27, 28*/ 'admin', 'parcels_admin', 'parcels', 'customer_admin', 'customer']
            }
          },
          {
            path: 'messages',
            name: 'messages',
            component: ParcelsMessagesList,
            meta: {
              permission: [/*0, 7, 8*/ 'admin', 'parcels_admin', 'parcels']
            }
          },
          {
            path: 'bypass',
            name: 'bypass',
            component: ParcelsBypassOrder,
            meta: {
              permission: [/*0, 7, 8*/ 'admin', 'parcels_admin']
            }
          }
        ]
      },
      {
        path: '/serials',
        //name: 'serials',
        alias: ['serials'],
        component: Products,
        meta: {
          permission: [/*0, 25*/ 'admin', 'sn_admin']
        },
        children: [
          {
            path: '/',
            name: 'serials',
            component: ProductsSerials,
            meta: {
              permission: [/*0, 25*/ 'admin', 'sn_admin']
            }
          }
        ],
      },
      {
        path: '/users',
        //name: 'users',
        alias: ['users'],
        component: Users,
        meta: {
          permission: [/*0, 9*/ 'admin', 'users_admin']
        },
        children: [
          {
            path: '/',
            name: 'users',
            component: UsersList,
            meta: {
              permission: [/*0, 9*/ 'admin', 'users_admin']
            }
          },
          {
            path: '/groups',
            name: 'groups',
            component: UsersGroups,
            meta: {
              permission: [/*0, 9*/ 'admin', 'users_admin']
            }
          }
        ]
      },
      {
        path: '/salary',
        //name: 'salary',
        alias: ['salary'],
        component: Salary,
        meta: {
          permission: [/*0*/ 'admin']
        },
        children: [
          {
            path: '/',
            name: 'salary-report',
            component: SalaryPayList,
            meta: {
              permission: [/*0*/ 'admin']
            }
          }
        ]
      },
      {
        path: '/lists',
        name: 'lists',
        component: Lists,
        meta: {
          mainOnly: true,
          permission: [/*0, 11, 12*/ 'admin', 'list_admin', 'list']
        },
        children: [
          {
            path: '/pick',
            name: 'picklists',
            component: ListsPickLists,
            meta: {
              mainOnly: true,
              permission: [/*0, 11, 12*/ 'admin', 'list_admin', 'list']
            }
          },
          {
            path: '/collect',
            name: 'collectlists',
            component: ListsCollectLists,
            meta: {
              mainOnly: true,
              permission: [/*0, 11, 12*/ 'admin', 'list_admin', 'list']
            }
          },
          {
            path: '/invoices',
            name: 'invoices',
            component: ListsInvoices,
            meta: {
              mainOnly: false,
              permission: [/*0, 11, 12*/ 'admin', 'list_admin', 'list']
            }
          }
        ]
      },
      {
        path: '/statistics',
        name: 'statistics',
        component: Statistics,
        meta: {
          permission: [/*0, 21, 22*/ 'admin', 'statistics_admin', 'statistics']
        },
        children: [
          {
            path: '/picker-stats',
            name: 'picker-stats',
            component: StatisticsPickerStats,
            meta: {
              permission: [/*0, 21, 22*/ 'admin', 'statistics_admin', 'statistics']
            }
          },
          {
            path: '/packer-stats',
            name: 'packer-stats',
            component: StatisticsPackerStats,
            meta: {
              permission: [/*0, 21, 22*/ 'admin', 'statistics_admin', 'statistics']
            }
          }
        ]
      },
      {
        path: '/dashboard/',
        component: Dashboard,
        meta: {
          permission: [/*0, 13, 14*/ 'admin', 'dashboard_admin', 'dashboard']
        },
        children: [
          {
            path: '/deadlines',
            name: 'dashboard-deadline',
            component: DashboardDeadline,
            props: true
          },
/*           {
            path: '/',
            name: 'dashboard-live',
            component: DashboardLive
          },
          {
            path: '/packer',
            name: 'dashboard-packer',
            component: DashboardPacker
          },
          {
            path: '/picker',
            name: 'dashboard-picker',
            component: DashboardPicker
          },
          {
            path: '/carrier',
            name: 'dashboard-carrier',
            component: DashboardCarrier
          },  */
/*           {
            path: '/dashorders',
            name: 'dashboard-orders',
            component: DashboardOrders,
          } */
        ]
      },
      {
        path: '/schedule',
        //name: 'schedule',
        alias: ['schedule'],
        component: Schedule,
        children: [
          {
            path: '/',
            name: 'schedule',
            component: ScheduleHoliday,
            meta: {
              permission: [/*0, 15, 16*/ 'admin', 'schedule_admin', 'schedule']
            }
          },
          {
            path: '/timesheet',
            name: 'timesheet',
            meta: {
              permission: [/*0, 15*/ 'admin', 'schedule_admin']
            }
          },
          {
            path: '/overview',
            name: 'schedule-overview',
            component: ScheduleHolidayOverview,
            meta: {
              permission: [/*0, 15, 16*/ 'admin', 'schedule_admin', 'schedule']
            }
          },
          {
            path: '/approve',
            name: 'schedule-approve',
            component: ScheduleHolidayApprove,
            meta: {
              permission: [/*0, 15*/ 'admin', 'schedule_admin']
            }
          }
        ]
      },
      {
        path: '/settings',
        name: 'settings',
        component: Settings,
        meta: {
          permission: [/*0, 7, 17, 18*/ 'admin', 'settings_admin', 'parcels_admin', 'parcels']
        },
        children: [
          {
            path: 'dashboard',
            name: 'settings-dashboard',
            component: Settings,
            meta: {
              permission: [/*0, 17, 18*/ 'admin', 'dashboard_admin', 'dashboard']
            }
          },
          {
            path: 'email',
            name: 'settings-email',
            component: Settings,
            meta: {
              permission: [/*0, 17, 18*/ 'admin', 'dashboard_admin', 'dashboard']
            }
          },
          {
            path: 'country',
            name: 'settings-country',
            component: Settings,
            meta: {
              permission: [/*0, 17, 18*/ 'admin', 'dashboard_admin', 'dashboard']
            }
          },
          {
            path: '/printer',
            name: 'settings-printer',
            component: Settings,
            meta: {
              ipLocked: true,
              permission: [/*0, 7, 8, 17*/ 'admin', 'settings_admin', 'parcels_admin', 'parcels']
            }
          },
          {
            path: '/qrcode',
            name: 'settings-qrcode',
            component: Settings,
            meta: {
              permission: [/*0, 17, 19, 20*/ 'admin', 'settings_admin', 'pick_admin', 'pick']
            }
          },
          {
            path: '/company-config',
            name: 'settings-company-config',
            component: Settings,
            meta: {
              permission: [/*0, 17, 18*/ 'admin', 'settings_admin', 'settings']
            }
          },
          {
            path: '/global-config',
            name: 'settings-global-config',
            component: Settings,
            meta: {
              permission: [/*0, 17*/ 'admin', 'settings_admin']
            }
          },
          {
            path: '/bulk-invoicing-config',
            name: 'settings-bulk-invoicing-config',
            component: Settings,
            meta: {
              permission: [/*0, 17*/ 'admin', 'settings_admin']
            }
          },
          {
            path: '/announcement-config',
            name: 'settings-announcement-config',
            component: Settings,
            meta: {
              permission: [/*0, 17*/ 'admin', 'settings_admin']
            }
          },
          {
            path: '/warehouse-map',
            name: 'settings-warehouse-map',
            component: Settings,
            meta: {
              permission: [/*0, 17*/ 'admin', 'settings_admin']
            }
          },
          {
            path: '/shipping-config',
            name: 'settings-shipping-config',
            component: Settings,
            meta: {
              permission: [/*0, 17, 18*/ 'admin', 'settings_admin']
            }
          },
          {
            path: '/packing-config',
            name: 'settings-packing-config',
            component: Settings,
            meta: {
              permission: [/*0, 17, 18*/ 'admin', 'settings_admin']
            }
          },
          {
            path: '/api-config',
            name: 'settings-api-config',
            component: Settings,
            meta: {
              permission: [/*0, 17*/ 'admin', 'settings_admin']
            }
          },
          {
            path: '/dvr-config',
            name: 'settings-dvr-config',
            component: Settings,
            meta: {
              permission: [/*0, 17*/ 'admin', 'settings_admin']
            }
          },
          {
            path: '/rma-config',
            name: 'settings-rma-config',
            component: Settings,
            meta: {
              permission: [/*0, 23*/ 'admin', 'rma_admin']
            }
          },
          {
            path: '/campaign-config',
            name: 'settings-campaign-config',
            component: Settings,
            meta: {
              permission: [/*0, 17*/ 'admin', 'settings_admin']
            }
          },
          {
            path: '/address-book',
            name: 'settings-address-book',
            component: Settings,
            meta: {
              permission: [/*0, 7, 8*/ 'admin', 'parcels_admin', 'parcels']
            }
          },
          {
            path: '/serial-number-config',
            name: 'settings-serial-number-config',
            component: Settings,
            meta: {
              //mainOnly: true,
              permission: [/*0, 17*/ 'admin', 'settings_admin']
            }
          },
          {
            path: '/printer-management',
            name: 'settings-printer-management',
            component: Settings,
            meta: {
              raspiOnly: true,
              permission: [/*0, 17*/ 'admin', 'settings_admin']
            }
          },
          {
            path: '/email-alert',
            name: 'email-alert',
            component: Settings,
            meta: {
              raspiOnly: true,
              permission: [/*0, 17*/ 'admin', 'settings_admin']
            }
          },
          {
            path: '/cache',
            name: 'settings-cache',
            component: Settings
          },
          {
            path: '/about',
            name: 'settings-about',
            component: Settings
          }
        ]
      },
      /*
      {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
          permission: [0, 9, 10]
        }
      }
      */
    ]
  },
  {
    path: '',
    component: LoginTemplate,
    children: [
      {
        path: '/login',
        name: 'login',
        component: Login
      }
    ]
  },
  {
    path: '',
    component: PublicTemplate,
    children: [
      {
        path: '/tracking',
        name: 'tracking',
        component: Tracking
      },
      {
        path: '/stats',
        name: 'stats',
        component: Stats
      },
      /*
      {
        path: '/punchin',
        name: 'punchin',
        component: Punchin
      }
      */
    ]
  },
  {
    path: '/deadlinesexternal/:token',
    name: 'dashboard-deadline-external',
    component: DashboardDeadline,
    props: true,
  },
  {
    path: '/dashboardpunchclock',
    name: 'dashboard-punch-clock',
    component: DashboardPunchClock,
  },
  {
    path: '',
    component: CleanTemplate,
    children: [
      {
        path: '/email/template/preview',
        name: 'PreviewEmailTemplate',
        component: PreviewEmailTemplate
      }
    ]
  },
  {
    path: '/rma-landing-page-preview',
    name:'RMA-Landing-Page-Preview',
    component: RMAPreview,
    props: true,
  },
  {
    path: '/rma-landing-page',
    name:'RMA-Landing-Page',
    component: RMAPreview,
  },
  { 
    path: '*', 
    component: NotFound
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;

import { Permissions } from '@/helpers/Permissions';
import { Config }      from '@/helpers/Config';
import { BPA }         from '@/helpers/BPA';

// Append subdomain to window title
window.document.title += ' - ' + BPA.util.GetCompany().split('').map((c, i) => !i ? c.toUpperCase() : c).join('');

router.beforeEach((to, from, next) => {
  const Check = (required) =>  BPA.permissions(required).length;
  const publicPages = ['/login', '/punchin', '/tracking', '/stats', ('/deadlinesexternal/' + to.params.token), '/dashboardpunchclock', ];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = BPA.session.auth();
  const views = routes[0].children;

  Config.ForceUpdateToLatestVersion();

  if (authRequired && !loggedIn) {
    BPA.session.ParcelOrderRedirect('set');
    return next('/login');
  } else {
    BPA.session.UpdateSession();
  }

  if ('ipLocked' in to.meta) {
    if (to.meta.ipLocked) {
      let next_name = null;
      let IP = BPA.util.GetStoredIP();
      let validation_passed = IP.packing && IP.public;
      validation_passed = validation_passed && Config.ValidatePublicIpAddress(IP.packing, IP.public);
      if (!validation_passed) {
        for (let i = 0; i < views.length; i++) {
          if (views[i].name == to.name) {
            for (let j = 0; j < i; j++) {
              if ('meta' in views[j] && !views[j].meta.ipLocked && Check(views[j].meta.permission)) {
                next_name = views[j].name;
                break;
              }
            }
            if (!next_name) {
              for (let j = i; j < views.length; j++) {
                if ('meta' in views[j] && !views[j].meta.ipLocked && Check(views[j].meta.permission)) {
                  if ('children' in views[j]) {
                    let first_child = views[j].children[0];
                    if (first_child) next_name = first_child.name;
                  } else {
                    next_name = views[j].name;
                  }
                  break;
                }
              }
            }
            break;
          }
        }
        if (next_name) {
          return next({name: next_name});
        }
      }
    }
  }
  
  if ('mainOnly' in to.meta) {
    if (!BPA.util.IsMainCompany()) {
      let view = null, route = null;
      for (let o = 0; o < views.length; o++) {
        if ('children' in views[o]) {
          const parent = views[o];
          let previous = null;
          for (let i = 0; i < parent.children.length; i++) {
            const child = parent.children[i];
            if (child.name == to.name) {
              view = parent;
              if (parent.children.length > 1) {
                route = previous;
              } else {
                route = child;
              }
              break;
            }
            previous = parent.children[i];
          }
        }
      }
      if (route || view) {
        return next({name: route ? route.name : view.name});
      }
    }
  }

  if ('permission' in to.meta) {
    if (Check(to.meta.permission)) {
      next();
    } else {
      let route = Permissions.GetFallback(routes);
      next({name: route});
    }
  } else {
    next();
  }
});
