import http from '../../public/http-status-codes.json';
import locale from '../../public/locale.json';
import phone from '../../public/phone.json';
export const BPA = {
  dev: JSON.parse(process.env.VUE_APP_DEV),
  host: (() => {
    let dev = JSON.parse(process.env.VUE_APP_DEV);
    let dev_host = process.env.VUE_APP_API_HOST_DEV;
    let api_host = process.env.VUE_APP_API_HOST;
    let location_protocol = location.protocol + '//';
    const has_protocol = (string) => /^http(s)?:\/\//.test(string);
    return dev ? ((!has_protocol(dev_host) ? location_protocol : '') + dev_host) 
    : ((!has_protocol(api_host) ? location_protocol : '') + api_host);
  })(),
  dev_host: (() => {
    let dev_host = process.env.VUE_APP_API_HOST_DEV;
    let location_protocol = location.protocol + '//';
    const has_protocol = (string) => /^http(s)?:\/\//.test(string);
    return (!has_protocol(dev_host) ? location_protocol : '') + dev_host;
  })(),
  storage: window.localStorage,
  platform(enquiry) {
    let navigator = window.navigator;
    let platform = String(navigator.platform).toLowerCase();
    let version = String(navigator.appVersion).toLowerCase();
    switch (true) {
      case /win/.test(platform):
        platform = 'windows';
        break;
      case /linux/.test(platform):
        platform = 'linux';
        break;
      case /mac/.test(platform):
        platform = 'mac';
        break;
      case /iphone/.test(platform):
        platform = 'iphone';
        break;
      case /ipad/.test(version):
        platform = 'ipad';
        break;
      case /android/.test(version):
        platform = 'android';
    }
    if (/x11; cros armv/.test(version)) {
      platform = 'raspberry pi';
    }
    if (enquiry) {
      return new RegExp(enquiry, 'i').test(platform);
    }
    return platform;
  },
  browser: (() => {
    let userAgent = window.navigator.userAgent;
    if (userAgent.includes('Firefox/')) return 'firefox';
    if (userAgent.includes('Edg/')) return 'edge';
    if (userAgent.includes('Chrome/')) return 'chrome';
    if (userAgent.includes('Safari/')) return 'safari';
  })(),
  http(status) {
    if (!status) return status;
    return http[status]['Reason Phrase'].toUpperCase();
  },
  locale(string = '', all = false) {
    if (Object.keys(locale).includes(string)) {
      return locale[string];
    }
    if (Object.values(locale).includes(string)) {
      return Object.keys(locale).find(key => locale[key] == string);
    }
    if (string == 'options') {
      let options = [];
      let locales = ['da_DK', 'de_DE', 'en_GB', 'no_NO', 'sv_SE'];
      let countries = this.api.Countries('GET');
      for (let code in locale) {
        let label = locale[code]/*.split(' (')[0]*/;
        if (all || locales.includes(code)) {
          if (/^[a-z]{2}_[A-Z]{2}$/.test(code)) {
            let option = {code, label};
            let iso_code_2 = option.code.split('_').slice(-1)[0];
            let country = countries.find(country => country.iso_code_2 == iso_code_2);
            if (countries.length && country) {
              /*
              let duplicate = options.find(option => option.id == country.id);
              if (!duplicate) {
                options.push({...option, ...{id: country.id}});
              } else {
                console.log(duplicate)
              }
              */
              options.push({...option, ...{country_id: country.id}});
            } else {
              options.push(option);
            }
          }
        }
      }
      options.sort((a, b) => {
        a = String(a.code).toUpperCase();
        b = String(b.code).toUpperCase();
        return a < b ? -1 : a > b ? 1 : 0;
      });
      return options;
    }
    return locale;
  },
  phone(identifier = '') {
    let prefix = '';
    if (identifier.length == 2) {
      prefix = phone[identifier.toUpperCase()];
    } else {
      const countries = this.api.Countries('GET');
      for (const country of countries) {
        if (country.name == identifier) {
          prefix = phone[country.iso_code_2];
          break;
        }
      }
    }
    return prefix;
  },
  company(property, identifier) {
    if (property && identifier) {
      let key = '';
      const company = BPA.api.Companies('GET');
      if (typeof identifier == 'string') {
        key = !isNaN(identifier) ? 'id' : identifier == identifier.toLowerCase() ? 'code' : 'name';
      } else if (typeof identifier == 'number') {
        key = 'id';
      }
      property = property.toLowerCase();
      for (let i = 0; i < company.length; i++) {
        if (key in company[i] && property in company[i]) {
          if (company[i][key] == identifier) {
            return company[i][property];
          }
        }
      }
    }
  },
  permissions(id, allowed) {
    let type = typeof id;
    let permissions = {};
    let permission_lookup = BPA.api.Permissions('GET');
    let user_permissions = BPA.session.auth().user.permission;
    const permission_id = (id) => Object.keys(permissions).find(key => {
      return permissions[key] == id;
    });
    if (Array.isArray(id)) type = 'array';
    for (let id in permission_lookup) {
      id = Number(id);
      let permission = permission_lookup[id];
      if (id == 0) permissions[id] = permission;
      if (typeof permission == 'object') {
        permissions[id] = permission.name;
        permissions[Object.keys(permission.option)] = permission.name + '_admin';
      }
    }
    const permission_filter = (allowed) => {
      for (let id in permissions) {
        id = Number(id);
        if (allowed == true ? !user_permissions.includes(id) : allowed == false ? user_permissions.includes(id) : false) {
          delete permissions[id];
        }
      }
    }
    if (typeof allowed == 'boolean') {
      permission_filter(allowed === true);
    } else if (type == 'boolean') {
      permission_filter(id === true);
    } else {
      permission_filter(!!id || null);
    }
    switch (type) {
      case 'number': return permissions[id] || null;
      case 'string': return permission_id(id) || null;
      case 'array': {
        let array = [];
        for (id of id) {
          type = typeof id;
          switch (type) {
            case 'number': array.push(permissions[id] || null); break;
            case 'string': array.push(permission_id(id) || null);
          }
        }
        return array.filter(id => id);
      }
    }
    return permissions;
  },
  cache: {
    key: 'cache',
    storage(type) {
      return window[(/local|session/i.test(type) ? type : 'session' ) + 'Storage'];
    },
    get(props = {}) {
      return JSON.parse(this.storage(props.type).getItem(this.key) || '{}');
    },
    set(props = {}) {
      this.storage(props.type).setItem(this.key, JSON.stringify({...this.get({type: props.type}), ...props.data}));
    },
    remove(props = {}) {
      let data = this.get({type: props.type}); delete data[props.item];
      this.storage(props.type).setItem(this.key, JSON.stringify(data));
    },
    clear(type) {
      this.storage(type).removeItem(this.key);
    },
    handler(props = {}) {
      if (!props.name) return;
      let cache = this.get({type: props.type});
      let data = cache[props.name] || {};
      if (props.get) {
        let get = {};
        let prop = props.get;
        if (typeof prop == 'string') {
          if (/,/.test(prop)) {
            prop = prop.split(',');
          } else {
            prop = [prop];
          }
        }
        if (Array.isArray(prop)) {
          for (let key of prop) {
            if (key in data) {
              get[key] = data[key];
            }
          }
        }
        return get;
      }
      if (props.set) {
        let set = {};
        let prop = props.set;
        for (let key in prop) {
          set[key] = prop[key];
        }
        data = {...data, ...set};
        this.set({type: props.type, data: {[props.name]: data}});
      }      
    },
    local(props = {}) {
      return this.handler({...props, ...{type: 'local'}});
    },
    session(props = {}) {
      return this.handler({...props, ...{type: 'session'}});
    }
  },
  printer: {
    key: 'printer',
    get() {
      return JSON.parse(BPA.storage.getItem(this.key) || '{}');
    },
    set(props = {}) {
      BPA.storage.setItem(this.key, JSON.stringify({...this.get(), ...props.data}));
    },
    remove(props = {}) {
      let data = this.get(); delete data[props.key];
      if (!Object.keys(data).length) return this.clear();
      BPA.storage.setItem(this.key, JSON.stringify(data));
    },
    clear() {
      BPA.storage.removeItem(this.key);
    },
    handler(props = {}) {
      if (!props.key) return;
      let stored = this.get();
      let data = stored[props.key] || '';
      if ('get' in props) {
        let get = {};
        let prop = props.get;
        if (typeof prop == 'string') {
          if (/,/.test(prop)) {
            prop = prop.split(',');
          } else {
            prop = [prop];
          }
        }
        if (Array.isArray(prop)) {
          for (let key of prop) {
            if (key in data) {
              get[key] = data[key];
            }
          }
        }
        if (!prop) {
          get = data;
        }
        return get;
      }
      if ('set' in props) {
        let set = {};
        let prop = props.set;
        if (!prop) {
          return this.remove({key: props.key});
        }
        if (prop instanceof Object) {
          for (let key in prop) {
            if (prop[key]) {
              set[key] = prop[key];
            } else {
              delete set[key];
            }
          }
          data = {...data, ...set};
          for (let key in data) {
            if (!(key in prop)) {
              delete data[key];
            }
          }
          return this.set({data: {[props.key]: data}});
        }
        this.set({data: {[props.key]: prop}});
      }      
    },
    fetch(key = '') {
      return this.handler({key, get: null});
    }
  },
  dvr: {
    key: 'dvr',
    get() {
      return JSON.parse(BPA.storage.getItem(this.key) || '{}');
    },
    set(props = {}) {
      BPA.storage.setItem(this.key, JSON.stringify({...this.get(), ...props.data}));
    },
    remove(props = {}) {
      let data = this.get(); delete data[props.key];
      if (!Object.keys(data).length) return this.clear();
      BPA.storage.setItem(this.key, JSON.stringify(data));
    },
    clear() {
      BPA.storage.removeItem(this.key);
    },
    handler(props = {}) {
      if (!props.key) return;
      let stored = this.get();
      let data = stored[props.key] || '';
      if ('get' in props) {
        let get = {};
        let prop = props.get;
        if (typeof prop == 'string') {
          if (/,/.test(prop)) {
            prop = prop.split(',');
          } else {
            prop = [prop];
          }
        }
        if (Array.isArray(prop)) {
          for (let key of prop) {
            if (key in data) {
              get[key] = data[key];
            }
          }
        }
        if (!prop) {
          get = data;
        }
        return get;
      }
      if ('set' in props) {
        let set = {};
        let prop = props.set;
        if (!prop) {
          return this.remove({key: props.key});
        }
        if (prop instanceof Object) {
          for (let key in prop) {
            if (prop[key]) {
              set[key] = prop[key];
            } else {
              delete set[key];
            }
          }
          data = {...data, ...set};
          for (let key in data) {
            if (!(key in prop)) {
              delete data[key];
            }
          }
          return this.set({data: {[props.key]: data}});
        }
        this.set({data: {[props.key]: prop}});
      }      
    },
    fetch(key = '') {
      return this.handler({key, get: null});
    }
  },
  session: {
    auth() {
      const user = JSON.parse(BPA.storage.getItem('user'));
      const token = JSON.parse(BPA.storage.getItem('user-token'));
      let host = JSON.parse(BPA.storage.getItem('api-host'));
      if ((!BPA.dev && host == BPA.dev_host) || (BPA.dev && host != BPA.dev_host)) {
        host = BPA.host;
      }
      //if (host) BPA.host = host;
      return user && token && host ? {auth: true, user, token} : false;
    },
    FlushAuth(user) {
      BPA.storage.setItem('user', JSON.stringify(user));
    },
    async host() {
      let url = window.location.origin;
      if (!BPA.dev && /local/.test(url)) {
        let host_location = new URL(BPA.host);
        let host_url = host_location.origin;
        let company_code = BPA.util.GetCompany();
        url = host_url.replace(/api/, company_code);
        //url = 'https://' + company_code + '.packship.eu';
      }
      return await new Promise((resolve, reject) => {
        window.fetch(url + '/api_server').then(response => {
          if (response && response.status == 200) return response.text();
        }).then(text => resolve(text && `${!BPA.dev ? new URL(url).protocol : 'https:'}//` + text)).catch(reject);
      });
    },
    async Login(request = {}) {
      if (!BPA.dev && !BPA.host) return;
      return await BPA.api.fetch({
        url: 'session', 
        body: request, 
        method: 'POST'
      }).then(response => {
        if (!response) return;
        if (response.status == 200) {
          return response.json();
        }
      }).then(async user => {
        if (!user) return;
        BPA.session.FlushAuth(user);
        BPA.storage.setItem('user-token', JSON.stringify(user.token));
        return BPA.api.Init().then(response => response);
      });
    },
    async Logout() {
      return await BPA.api.fetch({
        url: 'session',
        method: 'delete'
      }).then(response => {
        if (!response) return;
        if (response.status == 204) {
          BPA.session.FlushSession();
          window.location = '/login';
        }
        return response;
      });
    },
    async UpdateSession() {
      if (!BPA.session.auth()) {
        return BPA.session.FlushSession();
      }
      return await BPA.api.fetch('session').then(response => {
        if (!response) return;
        if (response.status == 200) {
          return response.json();
        } else if (response.status == 401) {
          BPA.session.FlushSession();
          window.location = '/login';
        }
      }).then(user => {
        if (!user) return;
        BPA.session.FlushAuth(user);
        BPA.session.ParcelOrderRedirect();
      });
    },
    GetParcelOrderUrl(id_only) {
      let order_id = null;
      let order_url = null;
      const url = window.location;
      const pathname = '/parcels/orders';
      const regex = new RegExp(pathname + '|/', 'ig');
      let filter = url.pathname.replace(regex, '').replace('?id=');
      const search = url.search.replace('?id=', '').split('&')[0];
      const params = new URLSearchParams(url.search).get('id');
      const isNull = (string) => string === 'null';
      filter = filter.replace('id', '').replace('=', '').split('&')[0];
      order_id = filter && (!isNaN(filter) || isNull(filter)) ? filter : order_id;
      if (!order_id) order_id = search && (!isNaN(search) || isNull(search)) ? search : order_id;
      if (!order_id) order_id = params && (!isNaN(params) || isNull(params)) ? params : order_id;
      order_url = url.origin + pathname + '?id=' + order_id;
      return order_id ? id_only ? order_id : order_url : null;
    },
    ParcelOrderRedirect(argument) {
      const session = window.sessionStorage;
      const order_url = this.GetParcelOrderUrl();
      const getOrder = (key = '') => {
        const order = JSON.parse(session.getItem('order'));
        return key.length ? order[key] : order;
      }
      const setOrder = (order = {}) => {
        const session_order = getOrder();
        order = {...order, ...session_order};
        session.setItem('order', JSON.stringify(order));
      }
      const removeOrder = () => {
        session.removeItem('order');
      }
      const goTo = (url = '') => {
        const session_order = getOrder();
        url = url ? url : session_order ? session_order.url : null;
        if (url && url != window.location.href) {
          window.location.href = url;
          removeOrder();
        }
      }
      if (argument !== false) {
        const session_order = getOrder();
        if (argument == 'set' && order_url) {
          setOrder({url: order_url});
        } else if (session_order) {
          goTo(session_order.url);
        } else {
          goTo(order_url);
        }
      } else {
        removeOrder();
      }
    },
    FlushSession() {
      const preserved = this.PreserveStorage();
      BPA.session.ParcelOrderRedirect('set');
      //window.sessionStorage.clear();
      BPA.storage.clear();
      delete BPA.session.auth.host;
      for (let [key, value] of Object.entries(preserved)) {
        BPA.storage.setItem(key, value);
      }
    },
    PreserveStorage() {
      const storage = {};
      const preserve = ['locale', 'printer', 'dvr', 'senderAddress'];
      for (let i = 0; i < BPA.storage.length; i++) {
        const key = BPA.storage.key(i);
        if (preserve.includes(key)) {
          storage[key] = BPA.storage.getItem(key);
        }
      }
      return storage;
    }
  },
  util: {
    GetCompany() {
      return String(window.location.hostname.split('.')[0]).toLowerCase();
    },
    CompanyDirectory(company) {
      return BPA.host + '/var/' + BPA.api.encode(company);
    },
    GetCompanyLogo(company) {
      return BPA.util.CompanyDirectory(company) + '/img/logo.png';
    },
    GetMainCompany() {
      return BPA.api.Companies('GET').find(company => company.main);
    },
    IsMainCompany() {
      return BPA.util.GetCompany() == BPA.util.GetMainCompany().code;
    },
    StoreIpAdresses(packingIp, publicIp) {
      BPA.storage.setItem('IP', JSON.stringify({packing: packingIp, public: publicIp}));
    },
    GetStoredIP() {
      return JSON.parse(BPA.storage.getItem('IP')) || {};
    },
    SetSenderAddress(address) {
      BPA.storage.setItem('senderAddress', JSON.stringify(address));
    },
    GetSenderAddress() {
      return JSON.parse(BPA.storage.getItem('senderAddress')) || {};
    },
    storage: {
      get(key = '') {
        return JSON.parse(BPA.storage.getItem(key) || '{}');
      },
      set(key = '', data = {}) {
        BPA.storage.setItem(key, JSON.stringify({...this.get(key), ...data}));
      }
    }
  },
  api: {
    base() {
      return BPA.host + '/api/' + this.encode(BPA.util.GetCompany());
    },
    encode(uri_component) {
      return window.encodeURIComponent(uri_component);
    },
    decode(uri_component) {
      return window.decodeURIComponent(uri_component.replace(/\+/g, ' '));
    },
    headers(no_content_type = false) {
      const headers = {authorization: BPA.session.auth().token};
      if (!headers.authorization) delete headers.authorization;
      if (!no_content_type) headers['Content-Type'] = 'application/json';
      return new Headers(headers);
    },
    timeout(ms = 300000) { // 5 minutes by default
      // https://dmitripavlutin.com/timeout-fetch-request/
      // In Chrome a network request timeouts at 300 seconds, while in Firefox at 90 seconds.
      const controller = new AbortController();
      const signal = {signal: controller.signal};
      if (!this.timeout.timer) {
        this.timeout.index = 0;
        this.timeout.timer = {};
        this.timeout.clear = (index) => {
          clearTimeout(this.timeout.timer[index]);
        }
      }
      this.timeout.index++;
      this.timeout.timer[this.timeout.index] = setTimeout(() => {controller.abort()}, ms);
      signal.timeout = this.timeout.index;
      return signal;
    },
    download(props = {}) {
      let file_type = 'file';
      let file_name = props.name || 'download';
      let anchor = document.createElement('a');
      if ('blob' in props) {
        anchor.href = window.URL.createObjectURL(props.blob);
        file_type = props.blob.type.split('/').pop();
      }
      file_name += '.' + (props.type || file_type);
      anchor.download = file_name;
      anchor.style.display = 'none';
      anchor.target = '_blank';
      if (props.new_tab) {
        window.open(anchor.href);
      } else {
        anchor.click();
      }      
      setTimeout(() => {
        window.URL.revokeObjectURL(anchor.href);
        anchor.remove();
      }, 100);
    },
    print(url = '') {
      let iframe = document.querySelector('iframe#print');
      if (!iframe) {
        iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.id = 'print';
        iframe.onload = () => {
          setTimeout(() => {
            iframe.focus();
            iframe.contentWindow.print();
          }, 1);
        }
        document.body.appendChild(iframe);
      }
      iframe.src = url;
    },
    parameters(params = {}) {
      let query = '';
      for (let prop in params) {
        let value = params[prop];
        if (value) {
          if (prop == 'query') {
            value = String(value).replace(/#/g, '%23');
            query = value + (!isNaN(value) ? query : this.encode(query));
          } else {
            /page?!_size|_?offset?/.test(prop) && value--;
            query += '&' + prop + '=' + this.encode(value);
          }
        } else {
          if (!isNaN(value)) {
            if (prop == 'query') {
              query = value + query;
            }
          }
        }
      }
      return query.replace('&', '?');
    },
    async fetch(request) {
      if (typeof request == 'string') request = {url: request};
      if (!Object.keys(request).some(prop => /url|body|method/i.test(prop))) return;
      let url = (!request.url.startsWith('/') ? '/' : '') + request.url, controller;
      const is_form_data = request.body && request.body instanceof FormData;
      const path = url.replace(new RegExp(this.base(), 'ig'), '').split('/');
      for (let i = 0; i < path.length; i++) {
        if (path[i] && isNaN(path[i]) && !/\?|=|&/g.test(path[i])) {
          path[i] = this.encode(this.decode(path[i]));
          //path[i] = this.encode(path[i]);
        }
      }
      /*
      let path = url.replace(new RegExp(this.base(), 'ig'), '');
      path = path.replace(/\\\//g, this.encode('/'));
      path = path.split('/');
      for (let i = 0; i < path.length; i++) {
        if (path[i] && isNaN(path[i]) && !/\?|=|&/g.test(path[i])) {
          path[i] = this.encode(this.decode(path[i]));
        }
      }
      */
      url = this.base() + path.join('/');
      delete request.url;
      request.method = /get|put|post|patch|delete/i.test(request.method) ? request.method : 'get';
      if (request.body) {
        if (typeof request.body != 'string' && !is_form_data) {
          request.body = JSON.stringify(request.body);
        }
      } else if (!/get/i.test(request.method)) {
        request.body = '';
      }
      request.headers = this.headers(is_form_data);
      request.method = request.method.toUpperCase();
      if (!('signal' in request)) controller = this.timeout();
      if (controller) request.signal = controller.signal;
      return await window.fetch(url, request).then(r => r).catch(error => {
        const response = {ok: false};
        if (/abort/ig.test(error)) {
          response.status = 408;
        } else {
          response.status = 444;
        }
        return response;
      }).then(response => {
        if (controller) this.timeout.clear(controller.timeout);
        return response;
      });
    },
    async response(props = {}) {
      let success = null;
      const output = async (result) => {
        if (result) props.response.result = await result;
        return props.response || {};
      }
      const ishtml = (string = '') => {
        try {
          let dom = new DOMParser().parseFromString(string, 'text/html');
          return dom.body.children.length > 0;
        } catch(e) {
          return false;
        }
      }
      const capitalize = (string = '') => {
        return string.charAt(0).toUpperCase() + string.substring(1);
      }
      const message = (status = {}, text = '') => {
        if (text && text.includes('<p>')) {
          let paragraph = /<p>(.*?)<\/p>/.exec(text);
          text = paragraph ? paragraph[1] : '';
        }
        status.text = status.text ? status.text.toUpperCase() : BPA.http(status.code);
        return (status.code ? status.code  + ': ' : '') + status.text + (text && !ishtml(text) ? ' - ' : '') + (text && !ishtml(text) ? capitalize(text) : '');
      }
      if (!props.response) {
        const error_message = message({code: 444, text: 'CONNECTION CLOSED WITHOUT RESPONSE'});
        if ('error' in props && typeof props.error == 'function') {
          props.error(error_message);
        } else if ('vue' in props && typeof props.vue == 'object') {
          props.vue.$eventHub.$emit('ShowMessages', {
            message: error_message,
            type: 'error',
            close: true
          });
        }
        return {};
      }
      const status = props.response.status;
      if ('success' in props) {
        success = props.success == status;
      } else {
        success = props.response.ok;
      }
      if (success) {
        const status_200 = status == 200;
        if ('return' in props) {
          if (typeof props.return == 'string' && /blob|json|text/i.test(props.return)) {
            if (status_200 || /text/i.test(props.return)) {
              return output(props.response[props.return.toLowerCase()]());
            }
          } else if (typeof props.return == 'function') {
            return output(props.return());
          }
          return output();
        }
        if ('blob' in props && typeof props.blob == 'function') {
          props.blob(); if (status_200) return output(props.response.blob());
        }
        if ('json' in props && typeof props.json == 'function') {
          props.json(); if (status_200) return output(props.response.json());
        }
        if ('text' in props && typeof props.text == 'function') {
          props.text(); return output(props.response.text());
        }
      } else { // Error handling
        let response_text = null;
        if ('text' in props.response && typeof props.response.text == 'function') {
          response_text = await props.response.text().then(text => text);
        }
        const error_message = message({
          code: props.response.status,
          text: props.response.statusText
        }, response_text);
        if ('error' in props && typeof props.error == 'function') {
          props.error(error_message);
        } /*else if ('vue' in props && typeof props.vue == 'object') {
          props.vue.$eventHub.$emit('ShowMessages', {
            message: error_message,
            type: 'error',
            close: true
          });
        }*/ else if (window.vue.$eventHub) {
          window.vue.$eventHub.$emit('ShowMessages', {
            message: error_message,
            type: 'error',
            close: true
          });
        }
      }
      return output();
    },
    async ValidateCompany() {
      let api_host = await BPA.session.host();
      if (!BPA.dev) {
        if (!api_host) return {
          statusText: 'Missing API host',
          ok: false,
        }; 
        BPA.host = api_host;
      }
      BPA.storage.setItem('api-host', JSON.stringify(BPA.host));
      return await this.fetch('company/check_company').then(response => {
        return response && response.status == 204;
      });
    },
    async Init() {
      let res_init_response = {};
      return await this.fetch('res/init').then(response => {
        if (response && response.status == 200) {
          res_init_response = response;
          return response.json();
        }
        BPA.session.FlushSession();
      }).then((res = {}) => {
        BPA.api.Countries('POST', res.CountryLookup);
        BPA.api.Couriers('POST', res.ParcelAgentCode);
        BPA.api.Permissions('POST', res.PermissionLookup);
        BPA.api.ScheduleReasons('POST', res.ScheduleReasonLookup);
        BPA.api.Companies('POST', res.CompanyLookup, res.MainCompanyId);
        return BPA.api.GetPublicIpAddress().then(publicIp => {
          BPA.util.StoreIpAdresses(res.PackingApiIpBind, publicIp);
          BPA.session.ParcelOrderRedirect();
        }).catch(e => e).then(() => res_init_response);
      }).catch(e => e).then(() => res_init_response);
    },
    Permissions(type = '', list = null) {
      let key = 'BPA-permissions';
      if (type == 'GET') {
        return JSON.parse(BPA.storage.getItem(key));
      } else if (type == 'POST') {
        let permissions = {};
        for (let i = 0; i < list.length; i++) {
          let el = list[i];
          if (el.id == 0) {
            permissions[el.id] = el.code;
            delete list[i];
          }
          if (el.code.includes('user_')) {
            permissions[el.id] = {
              name: el.code.split('_')[1],
              option: {}
            };
            delete list[i];
          }
        }
        for (let i = 0; i < list.length; i++) {
          let el = list[i];
          if (el !== undefined) {
            for (let permission in permissions) {
              let item = permissions[permission];
              if (el.code.includes(item.name)) {
                item.option[el.id] = 'Is admin?';
              }
            }
          }
        }
        BPA.storage.setItem(key, JSON.stringify(permissions));
      }
    },
    ScheduleReasons(type, list) {
      let key = 'BPA-ScheduleReasons';
      if (type == 'GET') {
        return JSON.parse(BPA.storage.getItem(key) || '{}');
      } else if (type == 'POST') {
        let reasons = {};
        for (let item of list) reasons[item.id] = item.code;
        BPA.storage.setItem(key, JSON.stringify(reasons));
      }
    },
    Countries(type, list) {
      let key = 'BPA-Countries';
      if (type == 'GET') {
        return JSON.parse(BPA.storage.getItem(key) || '[]');
      } else if (type == 'POST') {
        BPA.storage.setItem(key, JSON.stringify(
          list.sort((a, b) => {a = a.name; b = b.name; return a < b ? -1 : a > b ? 1 : 0})
        ));
      }
    },
    Companies(type, list, main) {
      let key = 'BPA-Companies';
      if (type == 'GET') {
        return JSON.parse(BPA.storage.getItem(key) || '[]');
      } else if (type == 'POST') {
        BPA.storage.setItem(key, JSON.stringify(
          list.map(item => ({...item, ...{main: item.id == main}})).sort((a, b) => {
            a = a.name; b = b.name; return a < b ? -1 : a > b ? 1 : 0;
          })
        ));
      }
    },
    Couriers(type, list) {
      let key = 'BPA-Couriers';
      if (type == 'GET') {
        return JSON.parse(BPA.storage.getItem(key) || '{}');
      } else if (type == 'POST') {
        BPA.storage.setItem(key, JSON.stringify(
          Object.keys(list).sort().reduce((obj, key) => {
            obj[key] = list[key]; return obj;
          }, {})
        ));
      }
    },
    async GetPublicIpAddress() {
      return await this.fetch('res/client/public_ip').then(response => {
        if (response && response.status == 200) return response.text();
      }).then(text => text || '').catch(error => error);
    },
    async GetServerTime() {
      return await this.fetch('res/server_time');
    },
    async GetUsers() {
      return await this.fetch('user');
    },
    async GetUser(user_id = '') {
      return await this.fetch('user/' + user_id);
    },
    async QueryUsers(query = '') {
      return await this.fetch('user/query/' + query);
    },
    async GetUserNextEmployeeNo() {
      return await this.fetch('user/next_employee_no');
    },
    async GetUserExport() {
      return await this.fetch('user/export');
    },
    async CreateUser(user = {}) {
      return await this.fetch({
        url: 'user', 
        body: user, 
        method: 'POST'
      });
    },
    async UpdateUser(params = {}) {
      const user_id = params.user_id;
      delete params.user_id;
      return await this.fetch({
        url: 'user/' + user_id,
        body: params.user_data || {},
        method: 'PUT'
      });
    },
    async DeleteUser(user_id = '') {
      return await this.fetch({
        url: 'user/' + user_id,
        method: 'DELETE'
      });
    },
    async GetUserImage(user_id = '') {
      return await this.fetch('user/img/' + user_id);
    },
    async UpdateUserImage(user = {}) {
      return await this.fetch({
        url: 'user/img/' + user.id, 
        body: JSON.stringify(user.image), 
        method: 'POST'
      });
    },
    async DeleteUserImage(user_id) {
      return await this.fetch({
        url: 'user/img/' + user_id, 
        method: 'DELETE'
      });
    },
    async GetMyData() {
      return await this.fetch('user/my_data');
    },
    async GetMySettings() {
      return await this.fetch('user/my_setting/data');
    },
    async SetMySettings(data = {}) {
      return await this.fetch({
        url: 'user/my_setting/data',
        body: JSON.stringify(data),
        method: 'POST'
      });
    },
    async RequestLeave(request = {}) {
      return await this.fetch({
        url: 'schedule',
        body: request,
        method: 'POST'
      });
    },
    async UpdateLeave(params = {}) {
      const leave_id = params.leave_id;
      delete params.leave_id;
      return await this.fetch({
        url: 'schedule/' + leave_id,
        body: params.leave_data || {},
        method: 'PUT'
      });
    },
    async RejectLeave(leave_id = '') {
      return await this.fetch({
        url: 'schedule/' + leave_id,
        method: 'DELETE'
      });
    },
    async GetLeave(params = []) {
      params.unshift(BPA.session.auth().user.user_id);
      return await this.fetch('schedule/' + params.join('/'));
    },
    async GetFutureLeave() {
      return await this.fetch('schedule/future');
    },
    async GetCompanyLeave(params = []) {
      return await this.fetch('schedule/' + params.join('/'));
    },
    async GetCompanyFutureLeave() {
      return await this.fetch('schedule/needs_to_be_approved');
    },
    async GetParcels(params = {}) {
      return await this.fetch('orders/search/' + this.parameters(params));
    },
    async GetParcel(order_id = '') {
      return await this.fetch('orders/' + order_id);
    },
    async GetPaperless(paperless_id = '') {
      return await this.fetch('orders/paperless/' + paperless_id);
    },
    async GetCompanyOrderCount(company_code = '') {
      return await this.fetch('orders/count' + (company_code ? '/' + company_code : ''));
    },
    async GetLanguageOrderCount(company_code = '') {
      return await this.fetch('orders/count/language' + (company_code ? '/for/' + company_code : ''));
    },
    async EnqueueOrderForInvoicing(order_id = '') {
      return await this.fetch({
        url: 'orders/' + order_id + '/enqueue_for_invoice',
        method: 'POST'
      });
    },
    async SetOrderBulkInvoicingSkip(params = {}) {
      return await this.fetch({
        url: 'orders/' + params.order_id + '/set_skip_product_for_bulk_invoicing/' + params.skip, // true/false
        method: 'POST'
      });
    },
    async SetOrderState(params = {}) {
      return await this.fetch({
        url: 'orders/' + params.order_id + '/set_state/' + params.state,
        method: 'POST'
      });
    },
    async SetOrderStateBulk(params = {}) {
      return await this.fetch({
        url: 'orders/set_state_bulk/' + params.state,
        body: params.orders,
        method: 'POST'
      });
    },
    async SetOrderItemTariff(params = {}) {
      return await this.fetch({
        url: 'order_tarif/set/item/' + params.order_item_id + '/to/' + params.tariff,
        method: 'POST'
      });
    },
    async SetOrderItemOriginCountry(params = {}) {
      return await this.fetch({
        url: 'order_country_of_origin/set/item/' + params.order_item_id + '/to/' + params.country_id,
        method: 'POST'
      });
    },
    async SetOrderWeight(params = {}) {
      return await this.fetch({
        url: 'order_weight/set/' + params.weight_kg + '/on/' + params.target_type + '/' + params.target_id,
        method: 'POST'
      });
    },
    async PrintOrder(params = {}) {
      return await this.fetch({
        url: 'orders/print/' + params.order_id + '/on/' + params.printer_id,
        method: 'POST'
      });
    },
    async DownloadOrder(order_id = '') {
      return await this.fetch({
        url: 'orders/download/' + order_id,
        method: 'POST'
      });
    },
    async GetActiveWarehouseMap() {
      return await this.fetch('picklist/active_warehouse_map')
    },
    async GetLists(params = {}) {
      return await this.fetch('picklist/search/list/' + this.parameters(params));
    },
    async SearchOrders(params = {}) {
      return await this.fetch('picklist/search/order' + this.parameters(params));
    },
    async CreatePicklistPathPreview(params = {}) {
      return await this.fetch({
        url: 'picklist/preview',
        body: params,
        method: 'POST'
      });
    },
    async CreatePicklist(params = {}) {
      return await this.fetch({
        url: 'picklist',
        body: params,
        method: 'POST'
      });
    },
    async PrintPicklist(params = {}) {
      const controller = this.timeout(900000); // 15 minutes
      return await this.fetch({
        url: 'picklist/print/' + params.list_id + '/on/' + params.printer_id,
        signal: controller.signal,
        method: 'POST'
      }).then(response => {
        this.timeout.clear(controller.timeout);
        return response;
      });
    },
    async PrintPicklistOverview(params = {}) {
      const controller = this.timeout(900000); // 15 minutes
      return await this.fetch({
        url: 'picklist/print_overview/' + params.list_id + '/on/' + params.printer_id,
        signal: controller.signal,
        method: 'POST'
      }).then(response => {
        this.timeout.clear(controller.timeout);
        return response;
      });
    },
    async GetPicklistConfig() {
      if (!BPA.util.IsMainCompany()) return;
      return await this.fetch('picklist/config');
    },
    async DownloadPicklist(list_id = '') {
      return await this.fetch('picklist/download/' + list_id);
    },
    async DownloadPicklistOverview(list_id = '') {
      return await this.fetch('picklist/download_overview/' + list_id)
    },
    async GetPicklist(list_id = '') {
      return await this.fetch('picklist/' + list_id);
    },
    async GetAvailableOrderCount(company_code = '') {
      return await this.fetch('picklist/count/available_orders/' + company_code);
    },
    async ReleasePaperlessCodes(picklist_id, wagon_number) {
      try {
        return await this.fetch({
          url: 'picklist/release_paperless_codes/' + picklist_id + '/wagon/' + wagon_number,
          method: 'POST'
        })
      } catch(e)
      {
        return e
      }
    },
    async GetShipmentLists(params = {}) {
      return await this.fetch('shipment_list/list/' + this.parameters(params));
    },
    async GetShipmentList(list_id = '') {
      return await this.fetch('shipment_list/list/' + list_id);
    },
    async GetShipmentListCount(company_code = '') {
      return await this.fetch('shipment_list/count/' + company_code);
    },
    async GetShipmentListOrders(params = []) {
      return await this.fetch('shipment_list/list/' + params.join('/'));
    },
    async CreateShipmentList(params = {}) {
      return await this.fetch({
        url: 'shipment_list/list',
        body: params,
        method: 'POST'
      });
    },
    async PrintShipmentList(params = {}) {
      const controller = this.timeout(900000); // 15 minutes
      return await this.fetch({
        url: 'shipment_list/list/print/' + params.list_id + '/on/' + params.printer_id,
        signal: controller.signal,
        method: 'POST'
      }).then(response => {
        this.timeout.clear(controller.timeout);
        return response;
      });
    },
    async DownloadShipmentList(list_id = '') {
      return await this.fetch('shipment_list/list/pdf/download/' + list_id);
    },
    async GetPackingOrder(order_id = '') {
      return await this.fetch('packing/order/' + order_id);
    },
    async BypassBarcodeCheck(order_id = '') {
      return await this.fetch({
        url: 'packing/order/' + order_id + '/bypass_barcode_check',
        method: 'POST'
      });
    },
    async StartPackingOrder(order_id = '') {
      return await this.fetch({
        url: 'packing/order_start/' + order_id,
        method: 'POST'
      });
    },
    async ClosePackingOrder(params = {}) {
      return await this.fetch({
        url: 'packing/order/' + params.order_id + '/close',
        keepalive: params.keepalive,
        method: 'POST'
      });
    },
    async CheckTnTBarcode(params = {}) {
      return await this.fetch(`packing/check_tracking_and_trace/${params.overview_id}/${params.barcode}`)
    },
    async GetPrinterList() {
      return await this.fetch('print_node/printers/list');
    },
    async GetPrinterDetails(printer_id = '') {
      return await this.fetch('print_node/printers/' + printer_id + '/details');
    },
    async TestPrint(printer_id) {
      let test_print_url = 'print_node/test_print'

      if(printer_id)
      {
        test_print_url += '?printer_id=' + printer_id
      }

      return await this.fetch({
        url: test_print_url,
        method: 'POST' 
      })
    },
    async PrintPackageLabel(params = {}) {
      return await this.fetch({
        url: 'packing/order_print/' + params.order_id + '/on/' + params.printer_id + '/from/' + params.packing_station + (params.disable_cache ? '?disable_cache' : ''),
        method: 'POST'
      });
    },
    async PunchIn() {
      return await this.fetch({
        url: 'punch_clock/punch_in',
        method: 'POST'
      });
    },
    async PunchOut(punch_id = '') {
      return await this.fetch({
        url: 'punch_clock/punch_out/' + punch_id,
        method: 'POST'
      });
    },
    async GetCurrentPunch() {
      return await this.fetch('punch_clock/current');
    },
    async GetLastPunches(limit = 0) {
      return await this.fetch('punch_clock/last' + (limit ? '?limit=' + limit : ''));
    },
    async GetPunchValidationCheck() {
      return await this.fetch('punch_clock/validate/check');
    },
    async ValidatePunchCheck(punch = {}) {
      return await this.fetch({
        url: 'punch_clock/' + (punch.validated ? 'validate' : 'delete') + '/' + punch.id,
        body: punch,
        method: 'POST'
      });
    },
    async GetPunchClockDashboard() {
      return await this.fetch('dashboard/punch_clock')
    },
    async GetPunchOutWorkDoneTodayReport() {
      return await this.fetch('report/work_done/to_day');
    },
    async GetNotPunchedOut() {
      return await this.fetch('punch_clock/not_punch_out');
    },
    async SearchPunches(query = '') {
      return await this.fetch('punch_clock/search/' + query);
    },
    async SetPunchType(punch = {}) {
      return await this.fetch({
        url: 'punch_clock/set_punch_type/' + punch.id + '/' + punch.type,
        method: 'POST'
      });
    },
    async UpdatePunch(punch = {}) {
      return await this.fetch({
        url: 'punch_clock/update/' + punch.id, 
        body: punch, 
        method: 'POST'
      });
    },
    async GetWorkDoneReport(params = {}) {
      return await this.fetch('report/work_done/from/' + params.start_date + '/to/' + params.end_date);
    },
    async GetPunchInterval(params = {}) {
      return await this.fetch('punch_clock/interval/' + params.start_date + '/to/' + params.end_date);
    },
    async GetAPISecret() {
      return await this.fetch('res/today_api_secret');
    },
    async GetCompanyConfig() {
      return await this.fetch('company/config');
    },
    async UpdateCompanyConfig(request = {}) {
      return await this.fetch({
        url: 'company/config', 
        body: JSON.stringify(request), 
        method: 'POST'
      });
    },
    async DeleteCompanyConfig(request = []) {
      return await this.fetch({
        url: 'company/config', 
        body: JSON.stringify(request), 
        method: 'DELETE'
      });
    },
    async UploadCompanyLogo(request = {}) {
      return await this.fetch({
        url: 'company/upload/img/company_logo', 
        body: request, 
        method: 'POST'
      });
    },
    async UploadCompanyData(request = {}) {
      return await this.fetch({
        url: 'company/data', 
        body: request, 
        method: 'POST'
      });
    },
    async GetCompanyData() {
      return await this.fetch('company/data');
    },
    async GetOrderFooterTranslation(locale = '') {
      return await this.fetch('company/data/footer/incoice/' + locale);
    },
    async GetCompanyCmsLink() {
      return await this.fetch('company/cms_link');
    },
    async GetCompanyApiKeys() {
      return await this.fetch('company/api_keys');
    },
    async UploadCompanyApiKeys(request = {}) {
      return await this.fetch({
        url: 'company/api_keys', 
        body: request, 
        method: 'POST'
      });
    },
    async UploadCompanyCmsLink(request = {}) {
      return await this.fetch({
        url: 'company/cms_link', 
        body: request, 
        method: 'POST'
      });
    },
    async UploadOrderFooterTranslation(request = {}) {
      let locale = request.locale;
      delete request.locale;
      return await this.fetch({
        url: 'company/data/footer/incoice/' + locale, 
        body: request, 
        method: 'POST'
      });
    },
    async GetCompanyHomepage(locale) {
      locale = locale ? '/' + locale : '';
      return await this.fetch('company/homepage' + locale);
    },
    async UploadCompanyHomepage(request = {}) {
      let locale = request.locale;
      locale = locale ? '/' + locale : '';
      delete request.locale;
      return await this.fetch({
        url: 'company/homepage' + locale,
        body: request,
        method: 'POST'
      });
    },
    async GetCompanyHomeCountry() {
      return await this.fetch('company/home_county');
    },
    async UploadCompanyHomeCountry(request = {}) {
      return await this.fetch({
        url: 'company/home_county', 
        body: JSON.stringify(request), 
        method: 'POST'
      });
    },
    async DeleteCompanyHomeCountry() {
      return await this.fetch({
        url: 'company/home_county', 
        method: 'DELETE'
      });
    },
    async GetCompanySettings() {
      return await this.fetch('company/settings');
    },
    async UpdateCompanySettings(request = {}) {
      return await this.fetch({
        url: 'company/settings', 
        body: JSON.stringify(request), 
        method: 'POST'
      });
    },
    async DeleteCompanySettings(request = []) {
      return await this.fetch({
        url: 'company/settings', 
        body: JSON.stringify(request), 
        method: 'DELETE'
      });
    },
    async GetCompanyWebhooks() {
      return await this.fetch('company/webhooks');
    },
    async UpdateCompanyWebhooks(request = {}) {
      return await this.fetch({
        url: 'company/webhooks', 
        body: JSON.stringify(request), 
        method: 'POST'
      });
    },
    async DeleteCompanyWebhooks(request = []) {
      return await this.fetch({
        url: 'company/webhooks', 
        body: JSON.stringify(request), 
        method: 'DELETE'
      });
    },
    async GetOrderCmsStatusHistory(order_id = '') {
      return await this.fetch('orders/cms/status_histories/' + order_id);
    },
    async UpdateOrderCmsStatusHistory(params = {}) {
      return await this.fetch({
        url: 'orders/cms/status_histories/' + params.order_id,
        body: params.request,
        method: 'POST'
      });
    },
    async GetOrderComments(params = []) {
      return await this.fetch('order_comments/' + params.join('/'));
    },
    async SetOrderComment(params = {}) {
      return await this.fetch({
        url: 'order_comments/' + params.order_id + '/' + params.comment_type,
        body: params.comment,
        method: 'POST'
      });
    },
    async GetCompanyUniqueIdentifierProperties() {
      return await this.fetch('company/product_unique_identifier');
    },
    async UpdateCompanyUniqueIdentifierProperties(params = {}) {
      return await this.fetch({
        url: 'company/product_unique_identifier/' + params.company_code + '/' + params.property + '/' + params.value,
        method: 'POST'
      });
    },
    async GetProductUniqueIdentifierChecks(company_code = '') {
      company_code = company_code ? '?company=' + company_code : '';
      return await this.fetch('product_unique_identifier/checks' + company_code);
    },
    async UpdateProductUniqueIdentifierChecks(params = {}) {
      return await this.fetch({
        url: 'product_unique_identifier/update/' + params.regex + '/with/' + params.barcode,
        method: 'POST'
      });
    },
    async DeleteProductUniqueIdentifierCheck(barcode = '') {
      return await this.fetch({
        url: 'product_unique_identifier/delete/' + barcode,
        method: 'DELETE'
      });
    },
    async SearchProductUniqueIdentifiers(identifier = '') {
      return await this.fetch('product_unique_identifier/search/' + identifier);
    },
    async GetProductUniqueIdentifiers() {
      return await this.fetch('product_unique_identifier/list');
    },
    async AddUniqueIdentifierToProduct(params = {}) {
      return await this.fetch({
        url: 'product_unique_identifier/add/' + params.identifier + '/to/' + params.order_item_id,
        method: 'POST'
      });
    },
    async HasGoogleMapsAPIKey() {
      return await this.fetch('orders/has_google_maps_api_key');
    },
    async EditShippingAddress(params = {}) {
      params = JSON.parse(JSON.stringify(params));
      delete params.address_edit.country;
      return await this.fetch({
        url: 'orders/' + params.order_id + '/shipping_address',
        body: params.address_edit,
        method: 'POST'
      });
    },
    async GetShippingOptions(company_code = '') {
      company_code = company_code ? '?company=' + company_code : '';
      return await this.fetch('order_shipping_description/option' + company_code);
    },
    async SetShippingOption(params = {}) {
      return await this.fetch({
        url: 'order_shipping_description/set/' + params.method + '/on/' + params.id,
        method: 'POST'
      });
    },
    async ToggleOrderPin(params = {}) {
      return await this.fetch({
        url: 'order_pinned/' + (!params.toggle ? 'un' : '') + 'pin/' + params.target_id,
        method: 'POST'
      });
    },
    async PinOrder(order_id = '') {
      return await this.fetch({
        url: 'order_pinned/pin/' + order_id,
        method: 'POST'
      });
    },
    async UnpinOrder(order_id = '') {
      return await this.fetch({
        url: 'order_pinned/unpin/' + order_id,
        method: 'POST'
      });
    },
    async GetLock(order_id = '') {
      return await this.fetch('order_lock/' + order_id);
    },
    async CreateLock(params = {}) {
      return await this.fetch({
        url: 'order_lock/' + params.id,
        body: {note: params.note},
        method: 'POST'
      });
    },
    async DeleteLock(order_id = '') {
      return await this.fetch({
        url: 'order_lock/' + order_id,
        method: 'DELETE'
      });
    },
    async GetAutoLock(order_id = '') {
      return await this.fetch('order_lock/auto/' + order_id);
    },
    async CreateAutoLock(params = {}) {
      return await this.fetch({
        url: 'order_lock/auto/' + params.id,
        body: params.body,
        method: 'POST'
      });
    },
    async DeleteAutoLock(order_id = '') {
      return await this.fetch({
        url: 'order_lock/auto/' + order_id,
        method: 'DELETE'
      });
    },
    async GetCustomer(customer_id = '') {
      return await this.fetch('customer/get/' + customer_id);
    },
    async GetCustomerOrders(customer_id = '', all_companies = false) {
      return await this.fetch('customer/orders/for/' + customer_id + (all_companies ? '?all_companies' : ''));
    },
    async SearchCustomers(params = {}) {
      return await this.fetch('customer/search/' + this.parameters(params));
    },
    async FindCustomerByLookup(params = {}) {
      return await this.fetch('customer/find/' + params.check_type + '/' + params.check_value);
    },
    async CreateCustomer(params = {}) {
      return await this.fetch({
        url: 'customer/create',
        body: JSON.stringify(params),
        method: 'POST'
      });
    },
    async UpdateCustomer(params = {}) {
      return await this.fetch({
        url: 'customer/update/' + params.customer_id,
        body: JSON.stringify(params.body),
        method: 'POST'
      });
    },
    async AddCustomerLookup(params = {}) {
      return await this.fetch({
        url: 'customer/lookup/add/' + params.customer_id,
        body: JSON.stringify(params.body),
        method: 'POST'
      });
    },
    async AddCustomerNote(params = {}) {
      return await this.fetch({
        url: 'customer/add_note/' + params.customer_id,
        body: JSON.stringify(params.body),
        method: 'POST'
      });
    },
    async DeleteCustomerLookup(lookup_id = '') {
      return await this.fetch({
        url: 'customer/lookup/' + lookup_id,
        method: 'DELETE'
      });
    },
    async DeleteCustomer(customer_id = '') {
      return await this.fetch({
        url: 'customer/delete/' + customer_id,
        method: 'DELETE'
      });
    },
    async GetCourierConfig(agent_code = '') {
      return await this.fetch('res/parcel_shop/' + agent_code);
    },
    async UpdateCourierConfig(params = {}) {
      return await this.fetch({
        url: 'res/parcel_shop/' + params.agent_code,
        body: params.body,
        method: 'POST'
      });
    },
    async CreateReturnLabel(params = {}) {
      let parameters = '?';
      for (let key in params) {
        if (!['order_id', 'agent_code', 'operation_code', 'packing_station'].includes(key)) {
          parameters += '&' + key + '=' + params[key];
        }
      }
      if (parameters == '?') parameters = '';
      parameters = parameters.replace(/^\?&/, '?');
      let request = {
        url: 'label_generator/return/' + params.order_id + '/using/' + params.agent_code + '/on/' + params.operation_code + '/from/' + params.packing_station + parameters,
        method: 'POST'
      };
      if ('body' in params) request.body = params.body;
      return await this.fetch(request);
    },
    async PrintShippingLabel(params = {}) {
      return await this.fetch({
        url: 'orders/print_shipping_label/' + params.order_id + '/on/' + params.printer_id + '/from/' + params.packing_station + (params.disable_cache ? '?disable_cache' : ''), 
        method: 'POST'
      });
    },
    async DownloadShippingLabel(params = {}) {
      return await this.fetch('orders/download_shipping_label/' + params.order_id + '/from/' + params.packing_station);
    },
    async GetOrderLabelStatus(params = []) {
      return await this.fetch('label_status/order/' + params.join('/'));
    },
    async GetLabelStatus(params = []) {
      return await this.fetch('label_status/' + params.join('/'));
    },
    async CancelLabel(params = {}) {
      return await this.fetch({
        url: 'label_cancel/' + params.agent_code + '/' + params.barcode, 
        method: 'DELETE'
      });
    },
    async GetInvoicingAbroadList(params = {}) {
      return await this.fetch('invoicing/parcel/list' + (params.query ? '/' : '') + this.parameters(params));
    },
    async GetInvoicingAbroadOrders(params = {}) {
      return await this.fetch('invoicing/parcel/' + params.courier + '/' + params.country + '/orders');
    },
    async GetInvoicingAbroadCount_deprecated(params = {}) {
      return await this.fetch('invoicing/parcel_count/carrier/' + params.courier + '/for/' + params.country);
    },
    async GetInvoicingAbroadCount(country_code = '') {
      return await this.fetch('invoicing/parcel_count/for/' + country_code);
    },
    async GetInvoicingAbroadItem(invoice_id = '') {
      return await this.fetch('invoicing/parcel/' + invoice_id);
    },
    async CreateInvoicingAbroadItem(params = {}) {
      return await this.fetch({
        url: 'invoicing/parcel/' + params.courier + '/' + params.country + '/new',
        body: JSON.stringify(params.body),
        method: 'POST'
      });
    },
    async PrintInvoicingAbroadItem(params = {}) {
      return await this.fetch({
        url: 'invoicing/print/' + params.invoice_id + '/on/' + params.printer_id,
        method: 'POST'
      });
    },
    async DownloadInvoicingAbroadItem(invoice_id = '') {
      return await this.fetch('invoicing/download/' + invoice_id);
    },
    async GetBulkInvoicingConfList(params = {}) {
      return await this.fetch('bulk_invoicing_conf/list' + (params.query ? '/' : '') + this.parameters(params));
    },
    async GetBulkInvoicingConfById(config_id = '') {
      return await this.fetch('bulk_invoicing_conf/get/' + config_id);
    },
    async SetBulkInvoicingConfState(params = {}) {
      return await this.fetch({
        url: 'bulk_invoicing_conf/set_active/'+ params.config_id + '/' + params.active, // true/false
        method: 'POST'
      });
    },
    async SetBulkInvoicingConfSendTo(params = {}) {
      return await this.fetch({
        url: 'bulk_invoicing_conf/send_to/'+ params.config_id,
        body: JSON.stringify(params.body),
        method: 'POST'
      });
    },
    async CreateBulkInvoicingConf(params = {}) {
      return await this.fetch({
        url: 'bulk_invoicing_conf/create/'+ params.agent_code + '/in/' + params.country_id,
        body: JSON.stringify(params.body),
        method: 'POST'
      });
    },
    async UpdateBulkInvoicingConf(params = {}) {
      return await this.fetch({
        url: 'bulk_invoicing_conf/update/'+ params.config_id,
        body: JSON.stringify(params.body),
        method: 'POST'
      });
    },
    async CopyBulkInvoicingConf(params = {}) {
      return await this.fetch({
        url: 'bulk_invoicing_conf/copy/' + params.config_id + '/to/' + params.agent_code + '/in/' + params.country_id,
        method: 'POST'
      });
    },
    async GetPickerStats(params = {}) {
      const request = {url: 'statistics/pick_list/from/' + params.start_date + '/to/' + params.end_date};
      if ('signal' in params) request.signal = params.signal;
      return await this.fetch(request);
    },
    async GetPickerStatsFromCache(cache_name = '') {
      return await this.fetch('statistics/pick_list/cache/' + cache_name);
    },
    async GetPickerStatsWagonBreaks(params = {}) {
      return await this.fetch('statistics/pick_list/' + params.pick_list + '/breaks/wagon/' + params.wagon);
    },
    async GetPackerStats(params = {}) {
      return await this.fetch('statistics/pack_list/from/' + params.start_date + '/to/' + params.end_date);
    },
    async GetPayListInterval(params = {}) {
      const controller = this.timeout(900000); // 15 minutes
      return await this.fetch({
        url: 'statistics/pay_list/from/' + params.start_date + '/to/' + params.end_date,
        signal: controller.signal
      }).then(response => {
        this.timeout.clear(controller.timeout);
        return response;
      });
      //return await this.fetch('statistics/pay_list/from/' + params.start_date + '/to/' + params.end_date);
    },
    async HasResParcelConf() {
      return await this.fetch('label_generator/has_res_parcel_conf');
    },
    async GetLabelGeneratorSetup() {
      return await this.fetch('label_generator/setup');
    },
    async GetLabelGeneratorTemplate(template) {
      return await this.fetch('label_generator/template/' + template.join('_'));
    },
    async GetParcelShops(params = {}) {
      let agent = params.agent_code;
      let country = params.country_code;
      delete params.agent_code;
      delete params.country_code;
      return await this.fetch('parcel_shops/find/' + agent + '/from/' + String(country).toLowerCase() + this.parameters(params));
    },
    async GetPostalCodes(country_code = '') {
      return await this.fetch('parcel_shops/zipcodes/' + String(country_code).toLowerCase());
    },
    async GetBringCustomerNumbers() {
      return await this.fetch('label_generator/support/bring/customer_numbers');
    },
    async GetBurdAvailability() {
      return await this.fetch('label_generator/support/burd/zipcodes_availability');
    },
    async GetInstaboxAvailability(params = {}) {
      return await this.fetch({
        url: 'label_generator/support/instabox/availability',
        body: params,
        method: 'POST'
      });
    },
    async CreateCustomLabel(params = {}) {
      return await this.fetch({
        url: 'label_generator/create/' + params.agent_code + '/using/' + params.shipping_method + '/' + params.operation_code, // download, print or email
        body: params.body,
        method: 'POST'
      });
    },
    async GetCustomLabels(params = {}) {
      return await this.fetch('label_generator/log/search/' + this.parameters(params));
    },
    async GetCustomLabel(shipment_id = '') {
      return await this.fetch('label_generator/log/' + shipment_id);
    },
    async GetCustomLabelCreateArgs(shipment_id = '') {
      return await this.fetch('label_generator/log/create_args/' + shipment_id);
    },
    async DownloadCustomLabel(shipment_id = '') {
      return await this.fetch('label_generator/log/download/pdf/' + shipment_id);
    },
    async PrintCustomLabel(params = {}) {
      return await this.fetch('label_generator/log/print/pdf/' + params.shipment_id + '/on/' + params.printer_id);
    },
    async GetAddressBook() {
      return await this.fetch('label_generator/predefined_address');
    },
    async GetItemFromAddressBook(address_id = '') {
      return await this.fetch('label_generator/predefined_address/' + address_id);
    },
    async AddItemToAddressBook(params = {}) {
      return await this.fetch({
        url: 'label_generator/predefined_address',
        body: params,
        method: 'POST'
      });
    },
    async UpdateItemInAddressBook(params = {}) {
      return await this.fetch({
        url: 'label_generator/predefined_address/' + params.address_id,
        body: params.body,
        method: 'POST'
      });
    },
    async RemoveItemFromAddressBook(address_id = '') {
      return await this.fetch({
        url: 'label_generator/predefined_address/' + address_id,
        method: 'DELETE'
      });
    },
    async GetUserGroupList() {
      return await this.fetch('res_user_group/list/res_user_group');
    },
    async GetUserGroupUserList() {
      return await this.fetch('res_user_group/list/res_users');
    },
    async GetUserGroup(group_id = '') {
      return await this.fetch('res_user_group/get/' + group_id);
    },
    async CreateUserGroup(group_name = '') {
      return await this.fetch({
        url: 'res_user_group/create/' + group_name,
        method: 'POST'
      });
    },
    async UpdateUserGroupName(params = {}) {
      return await this.fetch({
        url: 'res_user_group/update/' + params.group_id + '/set_name/' + params.group_name,
        method: 'POST'
      });
    },
    async AddUserToGroup(params = {}) {
      return await this.fetch({
        url: 'res_user_group/update/' + params.group_id + '/add_user/' + params.user_id,
        method: 'POST'
      });
    },
    async RemoveUserFromGroup(params = {}) {
      return await this.fetch({
        url: 'res_user_group/update/' + params.group_id + '/remove_user/' + params.user_id,
        method: 'POST'
      });
    },
    async DeleteUserGroup(group_id = '') {
      return await this.fetch({
        url: 'res_user_group/delete/' + group_id,
        method: 'DELETE'
      });
    },
    async SearchTrackingCampaigns(params = {}) {
      return await this.fetch('campaign_on_tracking/campaign/search/' + this.parameters(params));
    },
    async GetTrackingCampaign(campaign_id = '') {
      return await this.fetch('campaign_on_tracking/campaign/get/' + campaign_id);
    },
    async GetTrackingCampaignImage(params = []) {
      return await this.fetch('campaign_on_tracking/campaign/img/' + params.join('/'));
    },
    async GetTrackingCampaignFrontend(language = '') {
      return await this.fetch('campaign_on_tracking/campaign/frontend/' + language);
    },
    async CreateTrackingCampaign(request = {}) {
      return await this.fetch({
        url: 'campaign_on_tracking/campaign/create',
        body: request,
        method: 'POST'
      });
    },
    async EditTrackingCampaign(request = {}) {
      return await this.fetch({
        url: 'campaign_on_tracking/campaign/update/' + request.campaign_id,
        body: request.body,
        method: 'POST'
      });
    },
    async DeleteTrackingCampaign(campaign_id = '') {
      return await this.fetch({
        url: 'campaign_on_tracking/campaign/delete/' + campaign_id,
        method: 'POST'
      });
    },
    async SetTrackingCampaignStatus(params = {}) {
      return await this.fetch({
        url: 'campaign_on_tracking/campaign/update/' + params.campaign_id + '/status/' + params.active, // enable or disable
        method: 'POST'
      });
    },
    async GetCMSAuthStatus() {
      return await this.fetch('cms/authorization');
    },
    async CreateCMSAuthKey() {
      return await this.fetch({
        url: 'cms/authorization',
        method: 'POST'
      });
    },
    async DeleteCMSAuthKey() {
      return await this.fetch({
        url: 'cms/authorization',
        method: 'DELETE'
      });
    },
    async GetDVRAuthStatus() {
      return await this.fetch('dvr/authorization');
    },
    async CreateDVRAuthKey() {
      return await this.fetch({
        url: 'dvr/authorization',
        method: 'POST'
      });
    },
    async DeleteDVRAuthKey() {
      return await this.fetch({
        url: 'dvr/authorization',
        method: 'DELETE'
      });
    },
    async UploadOrderVideo(params = {}) {
      return await this.fetch({
        url: 'dvr/api/create/order/' + params.order_overview_id,
        body: params.body,
        method: 'POST'
      });
    },
    async GetOrderVideoList(order_id = '') {
      return await this.fetch('dvr/video_list/order/' + order_id);
    },
    async GetOrderVideo(video_id = '') {
      return await this.fetch('dvr/video/' + video_id);
    },
    async EnableRmaLanguage(language_code = '') {
      return await this.fetch({
        url: 'rma/' + language_code + '/enable',
        method: 'POST'
      });
    },
    async DisableRmaLanguage(language_code = '') {
      return await this.fetch({
        url: 'rma/' + language_code + '/disable',
        method: 'POST'
      });
    },
    async GetRmaImage(params = {}) {
      return await this.fetch('rma/' + params.rma_language + '/img/' + params.target);
    },
    async UploadRmaImage(params = {}) {
      return await this.fetch({
        url: 'rma/' + params.rma_language + '/img/' + params.target,
        body: params.body,
        method: 'POST'
      });
    },
    async GetOrderSMSTemplateList() {
      return await this.fetch('order_sms/template/list');
    },
    async GetOrderSMSTemplate(template_id = '') {
      return await this.fetch('order_sms/template/' + template_id);
    },
    async PreviewOrderSMSTemplate(params = {}) {
      return await this.fetch({
        url: 'order_sms/sms/preview/' + params.index,
        body: params.body,
        method: 'POST'
      });
    },
    async CreateOrderSMSTemplate(params = {}) {
      return await this.fetch({
        url: 'order_sms/template',
        body: params.body,
        method: 'POST'
      });
    },
    async UpdateOrderSMSTemplate(params = {}) {
      return await this.fetch({
        url: 'order_sms/template/' + params.template_id,
        body: params.body,
        method: 'POST'
      });
    },
    async DeleteOrderSMSTemplate(template_id = '') {
      return await this.fetch({
        url: 'order_sms/template/' + template_id,
        method: 'DELETE'
      });
    },
    async GetOrderSMSOrderList(params = {}) {
      if ('offset' in params) {
        params.query = params.offset;
        delete params.offset;
      }
      return await this.fetch('order_sms/order/list/' + this.parameters(params));
    },
    async GetOrderSMSOrderListByIds(ids = []) {
      return await this.fetch('order_sms/order/list/by/ids?ids=' + ids.join('s'));
    },
    async CreateOrderSMSMessage(params = {}) {
      return await this.fetch({
        url: 'order_sms/sms',
        body: params.body,
        method: 'POST'
      });
    },
    async GetOrderSMSList(order_id = '') {
      return await this.fetch('order_sms/sms/list_order/' + order_id);
    },
    async GetSMSAccountBalance() {
      return await this.fetch('order_sms/sms/account_balance');
    },
    async GetOrderSMSGroupList(params = {}) {
      if ('offset' in params) {
        params.query = params.offset;
        delete params.offset;
      }
      return await this.fetch('order_sms/sms/groups/' + this.parameters(params));
    },
    async GetOrderSMSGroup(group_id = '') {
      return await this.fetch('order_sms/sms/group/' + group_id);
    },
    async GetPaperlessMapping(query = '') { // e.g.: 1:110215838 or #000001
      return await this.fetch('paperless/mapping' + (query ? '/' + query : ''));
    },
    async CreatePaperlessCodePreview(request = {}) {
      return await this.fetch({
        url: 'paperless/code/preview',
        body: JSON.stringify(request),
        method: 'POST'
      });
    },
    async CreatePaperlessCodeDownload(request = {}) {
      return await this.fetch({
        url: 'paperless/code/create/download',
        body: request,
        method: 'POST'
      });
    },
    async CreatePaperlessCodePrint(params = {}) {
      return await this.fetch({
        url: 'paperless/code/create/on/' + params.printer_id + this.parameters(params.options),
        body: params.body,
        method: 'POST'
      });
    },
    async SearchWarehouseMaps(params = {}) {
      return await this.fetch('warehouse/map/search/' + this.parameters(params));
    },
    async GetWarehouseMap(map_id = '') {
      return await this.fetch('warehouse/map/get/' + map_id);
    },
    async CreateWarehouseMap(request = {}) {
      return await this.fetch({
        url: 'warehouse/map/create',
        body: JSON.stringify(request),
        method: 'POST'
      });
    },
    async UpdateWarehouseMap(params = {}) {
      return await this.fetch({
        url: 'warehouse/map/update/' + params.map_id,
        body: JSON.stringify(params.body),
        method: 'POST'
      });
    },
    async DeleteWarehouseMap(map_id = '') {
      return await this.fetch({
        url: 'warehouse/map/delete/' + map_id,
        method: 'DELETE'
      });
    },
    async CalculateWarehouseMap(params = {}) {
      return await this.fetch({
        url: 'warehouse/map/calc/' + params.map_id,
        body: JSON.stringify({title: params.title}),
        method: 'POST'
      });
    },
    async SearchWarehouseMapResults(params = {}) {
      return await this.fetch('warehouse/map/result/search/' + this.parameters(params));
    },
    async GetWarehouseMapResult(result_id = '') {
      return await this.fetch('warehouse/map/result/' + result_id);
    },
    async ActivateWarehouseMapResult(result_id = '') {
      return await this.fetch({
        url: 'warehouse/map/result/set_active/' + result_id,
        method: 'POST'
      });
    },
    async DeleteWarehouseMapResult(result_id = '') {
      return await this.fetch({
        url: 'warehouse/map/result/' + result_id,
        method: 'DELETE'
      });
    },
    async GetDrawWarehouseMapLink(list_id, wagon_number) {
      return this.fetch('warehouse_map_draw/get_link/picklist/' + list_id + '/wagon/' + wagon_number + '/using/active')
    },
    async GetDrawProductLinkToken(product_count, order_from) {
      return this.fetch('warehouse_map_draw/link/products?product_count=' + product_count + '&order_from=' + order_from)
    },
    async GetProductLinkList() {
      return await this.fetch('product_link/list');
    },
    async CreateProductLink(params = {}) {
      return await this.fetch({
        url: 'product_link/create',
        body: JSON.stringify(params),
        method: 'POST'
      });
    },
    async UpdateProductLink(params = {}) {
      return await this.fetch({
        url: 'product_link/update/' + params.id,
        body: JSON.stringify(params),
        method: 'POST'
      });
    },
    async DeleteProductLink(link_id = '') {
      return await this.fetch({
        url: 'product_link/delete/' + link_id,
        method: 'POST'
      });
    },
    async SearchAnnouncements(params = {}) {
      return await this.fetch('eula/list/' + this.parameters(params));
    },
    async GetAnnouncement(announcement_id = '') {
      return await this.fetch('eula/get/' + announcement_id);
    },
    async CreateAnnouncement(request = {}) {
      return await this.fetch({
        url: 'eula/create',
        body: request,
        method: 'POST'
      });
    },
    async UpdateAnnouncement(params = {}) {
      return await this.fetch({
        url: 'eula/update/' + params.id,
        body: params.body,
        method: 'POST'
      });
    },
    async DeleteAnnouncement(announcement_id = '') {
      return await this.fetch({
        url: 'eula/delete/' + announcement_id,
        method: 'DELETE'
      });
    },
    async GetAnnouncements() {
      return await this.fetch('eula/get_eula');
    },
    async ConfirmAnnouncement(params = {}) {
      let request = {
        url: 'eula/agree_with/' + params.id,
        method: 'POST'
      };
      if ('body' in params) {
        request.body = params.body;
      }
      return await this.fetch(request);
    },
    async SearchShippingMethods(params = {}) {
      return await this.fetch('shipping_handling_information/list/' + this.parameters(params));
    },
    async GetShippingMethod(params = {}) {
      return await this.fetch('shipping_handling_information/get/' + params.agent_code + '/' + params.shipping_method);
    },
    async SetShippingMethod(params = {}) {
      return await this.fetch({
        url: 'shipping_handling_information/set/' + params.agent_code + '/' + params.type + '/' + params.shipping_method,
        method: 'POST'
      });
    },
    async SetActiveShippingMethod(params = {}) {
      return await this.fetch({
        url: 'shipping_handling_information/set_active/' + params.active + '/' + params.agent_code + '/' + params.shipping_method,
        method: 'POST'
      });
    },
    async DeleteShippingMethod(params = {}) {
      return await this.fetch({
        url: 'shipping_handling_information/delete/' + params.agent_code + '/' + params.shipping_method,
        method: 'DELETE'
      });
    },
    async GetShippingCrateImage(params = {}) {
      return await this.fetch('shipping_handling_information/get_create_img/' + params.agent_code + '/' + params.shipping_method + '?' + new Date().getTime());
    },
    async SetShippingCrateImage(params = {}) {
      return await this.fetch({
        url: 'shipping_handling_information/set_create_img/' + params.agent_code + '/' + params.shipping_method,
        body: params.body,
        method: 'POST'
      });
    },
    async DeleteShippingCrateImage(params = {}) {
      return await this.fetch({
        url: 'shipping_handling_information/delete_create_img/' + params.agent_code + '/' + params.shipping_method,
        method: 'DELETE'
      });
    },
    async SetDefaultShippingCrateImage(params = {}) {
      return await this.fetch({
        url: 'shipping_handling_information/set_default_create_img',
        body: params,
        method: 'POST'
      });
    },
    async DeleteDefaultShippingCrateImage() {
      return await this.fetch({
        url: 'shipping_handling_information/delete_default_create_img',
        method: 'DELETE'
      });
    },
    async UpdateIsActiveAttribute(value, attribute, agent_code, shipping_method) {
      return await this.fetch({
        url: 'shipping_handling_information/attribute/set_active/' +  value + '/' + attribute + '/for/' + agent_code + '/' + shipping_method,
        method: 'POST',
      })
    },
    async UpdateAttributeValue(params = {}, attribute, agent_code, shipping_method) {
      return await this.fetch({
        url: 'shipping_handling_information/attribute/update/' + attribute + '/for/' + agent_code + '/' + shipping_method,
        body: params,
        method: 'POST'
      })
    },
    async SetCheckTrackAndTrace(params = {}) {
      return await this.fetch({
        url: `shipping_handling_information/set_check_tracking_and_trace/${params.toggle}/${params.courier}/${params.shipping_method}`,
        method: 'POST'
      })
    },
    async SearchShippingCrateRules(params = {}) {
      return await this.fetch('shipping_crate/rule/list/' + this.parameters(params));
    },
    async GetShippingCrateRuleOptions() {
      return await this.fetch('shipping_crate/rule/list/opt');
    },
    async SetShippingCrateRule(params = {}) {
      return await this.fetch({
        url: 'shipping_crate/rule/set/' + params.id + '/for/' + params.sku,
        method: 'POST'
      });
    },
    async DeleteShippingCrateRule(rule_id = '') {
      return await this.fetch({
        url: 'shipping_crate/rule/remove/' + rule_id,
        method: 'DELETE'
      });
    },
    async SearchShippingCrateTypes(params = {}) {
      return await this.fetch('shipping_crate/type/list/' + this.parameters(params));
    },
    async GetShippingCrateType(type_id = '') {
      return await this.fetch('shipping_crate/type/get/' + type_id);
    },
    async GetShippingCrateTypeImage(type_id = '') {
      return await this.fetch('shipping_crate/type/img/' + type_id + '?' + new Date().getTime());
    },
    async CreateShippingCrateType(params = {}) {
      return await this.fetch({
        url: 'shipping_crate/type/create',
        body: params,
        method: 'POST'
      });
    },
    async UpdateShippingCrateType(params = {}) {
      return await this.fetch({
        url: 'shipping_crate/type/update/' + params.id,
        body: params.body,
        method: 'POST'
      });
    },
    async DeleteShippingCrateType(type_id = '') {
      return await this.fetch({
        url: 'shipping_crate/type/delete/' + type_id,
        method: 'DELETE'
      });
    },
    async GetCourierEventConfig(agent_code = '') {
      return await this.fetch('courier_events/settings/get/' + agent_code);
    },
    async SetCourierEventConfig(params = {}) {
      return await this.fetch({
        url: 'courier_events/settings/set/' + params.agent_code,
        body: params.body,
        method: 'POST'
      });
    },
    async SearchAdditionalOrderProducts(params = {}) {
      return await this.fetch('order_product_additional/list/' + this.parameters(params));
    },
    async GetAdditionalOrderProduct(id = '') {
      return await this.fetch('order_product_additional/get/' + id);
    },
    async GetAdditionalOrderProductImage(id = '') {
      return await this.fetch('order_product_additional/img/' + id + '?' + new Date().getTime());
    },
    async CreateAdditionalOrderProduct(params = {}) {
      return await this.fetch({
        url: 'order_product_additional/create',
        body: params,
        method: 'POST'
      });
    },
    async UpdateAdditionalOrderProduct(params = {}) {
      return await this.fetch({
        url: 'order_product_additional/update/' + params.id,
        body: params.body,
        method: 'POST'
      });
    },
    async DeleteAdditionalOrderProduct(id = '') {
      return await this.fetch({
        url: 'order_product_additional/delete/' + id,
        method: 'DELETE'
      });
    },
    async GetOrderProductRequirePasswordPin() {
      return await this.fetch('order_product_require_password/pin');
    },
    async GetPackingRequirePasswordPin() {
      return await this.fetch('packing/require_password_pin');
    },
    async SetOrderProductRequirePasswordPin(pin = '') {
      return await this.fetch({
        url: 'order_product_require_password/pin',
        body: {pin},
        method: 'POST'
      });
    },
    async DeleteOrderProductRequirePasswordPin() {
      return await this.fetch({
        url: 'order_product_require_password/pin',
        method: 'DELETE'
      });
    },
    async SearchApprovalRequiredProductRules(params = {}) {
      return await this.fetch('order_product_require_password/rule/list/' + this.parameters(params));
    },
    async GetApprovalRequiredProductRule(id = '') {
      return await this.fetch('order_product_require_password/rule/' + id);
    },
    async CreateApprovalRequiredProductRule(params = {}) {
      return await this.fetch({
        url: 'order_product_require_password/rule',
        body: params,
        method: 'POST'
      });
    },
    async DeleteApprovalRequiredProductRule(id = '') {
      return await this.fetch({
        url: 'order_product_require_password/rule/' + id,
        method: 'DELETE'
      });
    },
    async GetDashboardToken() {
        return await this.fetch({
          url: 'deadlines/dashboard_token',
          method: 'GET'
        })
    },
    async CreateDashboardToken() {
      return await this.fetch({
        url: 'deadlines/dashboard_token',
        method: 'POST'
      })
    },
    async DeleteDashboardToken() {
      return await this.fetch({
        url: 'deadlines/dashboard_token',
        method: 'DELETE'
      })
    },
    async GetDashboardDeadlines() {
      return await this.fetch('deadlines/dashboard_data/internal')
    },
    async PostDeadline(params = {}) {
      try {
        return await this.fetch({
          url: 'deadlines/create',
          body: JSON.stringify(params),
          method: 'POST'
        });
      } catch(e)
      {
        return e
      }
    },
    async GetDeadlineLists(params = {}) {
      return await this.fetch('deadlines/list/' + this.parameters(params));
    },
    async DeleteDeadline(deadline_id) {
      return await this.fetch({
        url: '/deadlines/delete/' + deadline_id,
        method: 'DELETE'
      });
    },
    async PostRMAImage(params = {}) {
      return await this.fetch({
        url: '/' + params.language + '/img/' + params.target,
        body: params.body,
        method: 'POST'
      })
    },
    async GetRMAImage(params = {}) {
      return await this.fetch('/' + params.language + '/img/' + params.target)
    },
    async BypassUsedInPacking(params = []) {
      return await this.fetch({
        url: 'bypass_used_in_packing',
        body: JSON.stringify(params),
        method: 'POST'
      })
    },
    async UpdateAlertOrder(email, params = {}) {
      return await this.fetch({
        url: 'alert_order/update/' + email,
        body: JSON.stringify(params),
        method: 'POST'
      })
    },
    async DeleteAlertOrder(email) {
      return await this.fetch({
        url: 'alert_order/delete/' + email,
        method: 'DELETE'
      })
    },
    async GetAlertOrderByEmail(email) {
      return await this.fetch({
        url: 'alert_order/get/' + email,
        method: 'GET'
      })
    },
    async EnableAlertOrder(email) {
      return await this.fetch({
        url: 'alert_order/enable/' + email,
        method: 'POST'
      })
    },
    async DisableAlertOrder(email) {
      return await this.fetch({
        url: 'alert_order/disable/' + email,
        method: 'POST'
      })
    },
    async GetOrdersBySearch(searchQuery) {
      return await this.fetch('alert_order/search/' + this.parameters(searchQuery))
    },
    async GetAlertSettings() {
      return await  this.fetch({
        url: 'alert/settings/get',
        method: 'GET'
      });
    },
    async PostAlertSettings(params) {
      return await this.fetch({
        url: 'alert/settings/set',
        body: JSON.stringify(params),
        method: 'POST',
      })
    },
  }
}
