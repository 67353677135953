import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import init from './init';

import VueI18n from 'vue-i18n';
import { BPA } from '@/helpers/BPA';
import messages from '../public/translations/en_GB.json';

// Event bus
Vue.prototype.$eventHub = new Vue();

Vue.config.productionTip = false;

const locale = JSON.parse(BPA.storage.getItem('locale')) || {};

const Locale = code => (BPA.locale('options').find(option => option.code.startsWith(code)) || {}).code || 'en_GB';

locale.code = Locale(locale.code || navigator.language);
locale.loaded = [];

const i18n = new VueI18n({
  locale: 'en_GB',
  messages,
  silentTranslationWarn: true
});

window.vue = new Vue({
  router,
  store,
  init,
  i18n,
  render: h => h(App)
}).$mount('#app');

window.fetch('/translations/' + locale.code + '.json?' + new Date().getTime()).then(response => {
  if (response && response.status == 200) return response.json();
}).then(json => {
  if (!json) return;
  i18n._vm.messages = {...messages, ...json};
  i18n._vm.locale = locale.code;
  locale.loaded.push(locale.code);
  document.documentElement.lang = locale.code.split('_')[0];
  window.locale = Locale(locale.code).replace(/_/g, '-');
  BPA.storage.setItem('locale', JSON.stringify(locale));
}).catch(error => error);