(function(app) {
  app.init = (...values) => {
    const storage = window.localStorage;
    values.forEach(value => {
      let key = '';
      switch (typeof value) {
        case 'number':
          key = 'Printer';
          new Array('A4Printer', 'labelPrinter').forEach(key => {
            storage.setItem(key, JSON.stringify({id: value.toString()}));
          });
          break;
        case 'string':
          if (!value.trim().length) {
            return;
          }
          if (!isNaN(value)) {
            app.init(parseInt(value));
            return;
          }
          key = 'Station';
          storage.setItem('packingStation', value);
      }
      console.log(key + ' -> ' + value);
    });
    // Example: init(69541936, 'Test')
    return 'Done';
  }
  app.settings = (props = {}) => {
    if (!Object.keys(props).length) {
      console.warn('No setting properties');
      return;
    }
    const storage = window.localStorage;
    for (let param in props) {
      if (param == 'printer') {
        if (typeof props.printer == 'object') {
          for (let type in props.printer) {
            const printer_type = type.toLowerCase();
            if (printer_type == 'a4' || printer_type == 'label') {
              const key = (printer_type == 'a4' ? 'A4' : 'label') + 'Printer';
              const value = props.printer[type].toString();
              storage.setItem(key, JSON.stringify({id: value}));
              console.log((printer_type == 'a4' ? 'A4' : 'Label') + ' printer -> ' + value);
            }
          }
        }
      } else if (param == 'station') {
        if (props.station.toString().trim().length) {
          const value = props.station.trim().toString();
          storage.setItem('packingStation', value);
          console.log('Packing station -> ' + value);
        }
      } else {
        console.error('Unknown parameter: ' + param);
      }
    }
    // Example: settings({printer: {a4: 69541936, label: 69533702}, station: 'Test'})
    return 'Done';
  }
})(window);